import styled from 'styled-components'

export const Page = styled.div<{type: 'primary' | 'secondary'}>`
  display: flex;
  min-height: 100vh;
  position: relative;
  background: ${props => props.theme.secondaryBackgroundColor};
  overflow-y: scroll;
  flex-direction: column;
`

export const MainContent = styled.div`
	flex: 1;
`

export const Content = styled.main`
  padding: 20px;
  width: 100%;
  max-width: 572px;
  border-radius: 24px;
  margin: 0 auto;
  background: ${props => props.theme.primaryBackgroundColor};
  @media (max-width: 768px) {
    background: ${props => props.theme.secondaryBackgroundColor};
  }

`

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const LogoLoaderContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  justify-content: center;
`