import styled, { css } from "styled-components"
import { TTheme } from "types"
import { NetworkSwitcher } from '..'

export const Header = styled.header`
  max-width: 1280px;
  padding: 26px 56px;
  display: grid;
  margin: 0 auto;
  width: 100%;
  grid-template-columns: 1fr max-content max-content max-content;
  gap: 16px;
  align-items: center;
  @media (width < 768px) {
    display: none;
  }
`

export const HeaderLogoContainer = styled.div`

`
export const HeaderLogoLink = styled.div`
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
`


export const HeaderLogo = styled.img`
  max-width: 107px;
  max-height: 32px;
`

export const NetworkSwitcherStyled = styled(NetworkSwitcher)<{
  currentTheme?: TTheme
}>`
  padding-left: 24px;
  padding-right: 24px;
  border-radius: 36px;
  transition: border-color .3s, background-color .3s, color .3s;
  &:hover {
    ${props => {
      return css`
        border-color: transparent;
        background-color: ${props => props.theme.tagDefaultColor};
      `
    }};
  }

  & > div > div {
    font-size: 14px;
  }
`

export const Account = styled.div`
  height: 36px;
  margin-left: auto;
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 2px 0 24px;
  color: ${props => props.theme.primaryTextColor};
  justify-content: center;
  font-size: 14px;
  border-radius: 36px;
  border: 1px solid ${props => props.theme.primaryBorderColor};
  background: ${props => props.theme.primaryBackgroundColor};
`

export const Address = styled.div`
  height: 28px;
  border-radius: 28px;
  margin-left: 16px;
  background: ${props => props.theme.secondaryBackgroundColor};
  padding-left: 14px;
  color: ${props => props.theme.primaryTextColor};

  padding-right: 14px;
  display: flex;
  align-items: center;
`

export const Logout = styled.div<{
  currentTheme?: TTheme
}>`
  border-radius: 36px;
  border: 1px solid ${props => props.theme.primaryBorderColor};
  background: ${props => props.theme.primaryBackgroundColor};
  width: 36px;
  height: 36px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .3s;
  svg {
    path {
      stroke: ${props => props.theme.primaryTextColor};
    }
  }
  &:hover {
    ${props => {
      return css`
        border-color: transparent;
        background-color: ${props => props.theme.tagDefaultColor};
        
      `
    }};
  }

  
`

export const Balance = styled.span`
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: 'ss01' on;
  letter-spacing: -0.02em;
`