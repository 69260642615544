import styled from "styled-components"
import { GradientWrapper, PoweredByFooter } from 'components/pages/common'
import { CopyContainer } from 'linkdrop-ui'

export const PoweredByFooterStyled = styled(PoweredByFooter)`
  margin-top: 16px;
`
export const GradientWrapperStyled = styled(GradientWrapper)`
  margin-bottom: 12px;
  min-height: auto;
`

export const Amount = styled.h3`
  font-size: 32px;
  text-align: center;
  line-height: 40px;
  margin: 0 0 12px;
  color:  ${props => props.theme.primaryTextColor};
`

export const CopyContainerStyled = styled(CopyContainer)`
  max-width: 343px;
  display: none;
  @media (max-width: 767px) {
    display: block;
  }
`
