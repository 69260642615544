import styled, { css } from "styled-components"
import { Input, Button } from 'components/common'
import { TTheme } from "types"

export const BorderedBlock = styled.div<{ error?: boolean }>`
  position: relative;
  padding: 24px 16px 16px;
  border-radius: 16px;
  border: 1px solid;
  margin-bottom: 12px;
  transition: border-color .3s;
  border-color: ${props => {
    return props.error ? props.theme.errorColor : props.theme.primaryBorderColor
  }};

  &:has(input:focus),
  &:has(input:focus-visible) {
    border-color: ${props => {
      if (props.error) {
        return props.theme.errorColor
      }
      return props.theme.primaryHighlightColor
    }};
  }
`

export const Container = styled.div``

export const ErrorContainer = styled.div<{ error?: boolean }>`
  font-size: 11px;
  line-height: 16px;
  color: ${props => props.theme.errorColor};
`

export const Title = styled.h2`
  margin: 0 0 20px;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.primaryTextColor};

`

export const ButtonStyled = styled(Button)`
  position: absolute;
  bottom: 16px;
  right: 16px;
`

export const InputStyled = styled(Input)`
  margin-bottom: 12px;
`

export const Balance = styled.span`
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${props => props.theme.primaryTextColor};

`

export const BalanceValue = styled.span`
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: 'ss01' on;
  letter-spacing: -0.02em;
`

export const ChooseAssetButton = styled.div<{
  hideTokenList?: boolean,
  currentTheme?: TTheme
}>`
  position: absolute;
  right: 16px;
  top: 16px;
  font-size: 14px;
  display: flex;
  line-height: 1;
  align-items: center;
  border-radius: 18px;
  color: ${props => props.theme.primaryTextColor};
  border: 1px solid ${props => props.theme.primaryBorderColor};
  padding: 10px 16px;
  transition: border-color .3s;
  ${props => !props.hideTokenList && css`
    cursor: pointer;
  `}
  transition: border-color .3s, background-color .3s;

  svg {
    path {
      stroke: ${props => props.theme.primaryTextColor};
    }
  }


  &:hover {
    ${props => {
      return css`
        border-color: transparent;
        background-color: ${props => props.theme.tagDefaultColor};
      
      `
    }};
  }
`

export const TokenImage = styled.img`
  margin-right: 8px;
  max-width: 16px;
  border-radius: 16px;
`

export const TokenSymbol = styled.span`
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 50px;
  margin-right: 4px;
`