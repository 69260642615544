import styled, { css } from 'styled-components'
import {
  InputContainerProps,
  InputFieldProps,
  InputTitleProps,
  InputFieldContainerProps,
  InputAdditionalNoteProps
} from './types'

export const InputContainer = styled.div.attrs((props) => ({
  className: props.className,
}))<InputContainerProps>`
  margin-bottom: 12px;
  width: 100%;
  box-sizing: border-box;
  font-family: CoinbaseSans, Arial, Helvetica;
`

export const InputTitle = styled.h3<InputTitleProps>`
  margin: 0 0 12px;
  margin-bottom: 4px;
  font-weight: 500;
  font-size: 14px;

  color: ${(props) => props.theme.primaryTextColor};
  ${(props) =>
    props.error &&
    css`
      color: ${(props) => props.theme.inputErrorColor};
    `}
  
  ${(props) =>
    props.disabled &&
    css`
      color: ${(props) => props.theme.inputDisabledTextColor};
    `}
`

export const InputFieldContainerClass = 'InputFieldContainerClass'

export const InputFieldContainer = styled.div<InputFieldContainerProps>`
  color: ${(props) => props.theme.primaryTextColor};
  font-size: 16px;
  width: 100%;
  display: flex;
  line-height: 20px;
  font-weight: 400;
  align-items: center;
  padding: 0px 16px;
  border: 2px solid;
  border-color: ${(props) => props.theme.primaryHighlightColor};
  border-radius: 8px;
  transition: border-color 0.3s;
  height: 44px;
  font-size: 16px;
  background-color: ${props => props.theme.primaryColor};

  

  ${(props) =>
    props.error &&
    css`
      border: 2px solid ${props => props.theme.inputErrorColor};
      &:focus {
        border-color: ${(props) => props.theme.inputErrorColor};
        outline: none;
      }
    `}

  ${(props) =>
    props.disabled &&
    css`
      border: 1px solid ${(props) => props.theme.inputDisabledBorderColor};
      color: ${(props) => props.theme.inputDisabledTextColor};
    `}

  &:has(input:focus) {
    ${props => !props.error && css`
      border-color: ${(props) => props.theme.primaryHighlightColor};
      outline: none;
    `}
  }

  &:has(input:hover:not(:focus)) {
    ${(props) =>
      !props.error &&
      !props.disabled &&
      css`
        border-color: ${(props) => props.theme.inputHoverBorderColor};
        outline: none;
      `}

`

export const InputField = styled.input<InputFieldProps>`
  color: ${(props) => props.theme.primaryTextColor};
  outline: none;
  margin: 0;
  background: none;
  height: 100%;
  padding: 11px 0;
  border: none;
  border-radius: 8px;
  line-height: 1;
  width: 100%;
  font-size: 14px;

  ::placeholder {
    color: ${(props) => props.theme.additionalTextColor};
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  [type=number] {
    -moz-appearance: textfield;
  }

  ${(props) =>
      props.disabled &&
      css`
        cursor: not-allowed;
        color: ${(props) => props.theme.inputDisabledTextColor};
      `}

  ${(props) =>
    props.error &&
    css`

    `}
`
