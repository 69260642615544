import { TProps } from "./types"
import { FC } from 'react'
import {
  ButtonListItem,
  Container,
  ItemDescription,
  ItemStatus,
  ItemTitle,
  ItemValue,
  ItemIcon,
  ItemMain,
  ItemSecondary,
  NotFoundTitle,
  NotFoundIcon,
  RecommendedSpan,
  LoadingOverlay
} from './styled-components'
import Icons from "icons"
import { Loader } from 'components/common'
import { useQuery } from "hooks"

const defineValue = (
  value?: string,
  status?: string,
  recommended?: boolean,
  loading?: boolean
) => {
  if (loading) {
    return 'loading'
  }

  // if (recommended) {
  //   return <RecommendedSpan>Recommended</RecommendedSpan>
  // }

  if (!value) {
    return <Icons.ArrowRightIcon />
  }

  return <>
    <ItemValue>{value}</ItemValue>
    {status && <ItemStatus>{status}</ItemStatus>}
  </>
}

const ButtonList: FC<TProps> = ({
  items,
  notFoundTitle = 'Not found',
  loading
}) => {
  const query = useQuery()
  const currentTheme = query.get('theme') === 'light' ? 'light' : 'dark'
  const itemsToShow = items.map(item => {
    if (!item) { return }
    return <ButtonListItem
    currentTheme={currentTheme}
    onClick={() => {
      item.onClick && item.onClick()}
    }>
      {item.icon && <ItemIcon src={item.icon}/>}
      <ItemMain>
        <ItemTitle>{item.title}</ItemTitle>
        {item.description && <ItemDescription>{item.description}</ItemDescription>}
      </ItemMain>
      <ItemSecondary>
        {defineValue(
          item.value,
          item.status,
          item.recommended,
          item.loading
        )}
      </ItemSecondary>
    </ButtonListItem>
  })
  return <Container>
    {loading && <LoadingOverlay>
      <Loader />  
    </LoadingOverlay>}
    {itemsToShow.length > 0 ? itemsToShow : <NotFoundTitle>
      <NotFoundIcon>
        <Icons.TimerIcon />
      </NotFoundIcon>
      {notFoundTitle}
    </NotFoundTitle>}
  </Container>
} 

export default ButtonList