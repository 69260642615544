type TDefineTokenAddressBySymbol = (
  symbol: string,
  chain: number | null
) => string

const defineTokenAddressBySymbol: TDefineTokenAddressBySymbol = (
  symbol,
  chain
) => {

  if (chain === 666666666) {
    if (!symbol) {
      return '0x0000000000000000000000000000000000000000'
    }
    switch (symbol.toLocaleLowerCase()) {
      case 'degen':
      default:
        return '0x0000000000000000000000000000000000000000'
    }
  }

  if (chain === 42161) {
    if (!symbol) {
      return '0xaf88d065e77c8cC2239327C5EDb3A432268e5831'
    }
    switch (symbol.toLocaleLowerCase()) {
      case 'eth':
        return '0x0000000000000000000000000000000000000000'
      case 'usdc':
      default:
        return '0xaf88d065e77c8cC2239327C5EDb3A432268e5831'
    }
  }

  if (chain === 10) {
    if (!symbol) {
      return '0x0b2c639c533813f4aa9d7837caf62653d097ff85'
    }
    switch (symbol.toLocaleLowerCase()) {
      case 'eth':
        return '0x0000000000000000000000000000000000000000'
      case 'usdc':
      default:
        return '0x0b2c639c533813f4aa9d7837caf62653d097ff85'
    }
  }

  if (chain === 43114) {
    if (!symbol) {
      return '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E'
    }
    switch (symbol.toLocaleLowerCase()) {
      case 'eth':
        return '0x0000000000000000000000000000000000000000'
      case 'usdc':
      default:
        return '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E'
    }
  }


  
  if (chain === 11155111) {
    if (!symbol) {
      return '0x1c7d4b196cb0c7b01d743fbc6116a902379c7238'
    }
    switch (symbol.toLocaleLowerCase()) {
      case 'matic':
        return '0x0000000000000000000000000000000000000000'
      case 'usdc':
      default:
        return '0x1c7d4b196cb0c7b01d743fbc6116a902379c7238'
    }
  }

  if (chain === 8453) {
    if (!symbol) {
      return '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913'
    }
    switch (symbol.toLocaleLowerCase()) {
      case 'eth':
        return '0x0000000000000000000000000000000000000000'
      case 'usdc':
      default:
        return '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913'
    }
  }

  if (chain === 84531) {
    if (!symbol) {
      return '0xf175520c52418dfe19c8098071a252da48cd1c19'
    }
    switch (symbol.toLocaleLowerCase()) {
      case 'eth':
        return '0x0000000000000000000000000000000000000000'
      case 'usdc':
      default:
        return '0xf175520c52418dfe19c8098071a252da48cd1c19'
    }
  }

  if (chain === 100) {
    switch (symbol.toLocaleLowerCase()) {
      case 'xdai':
        return '0x0000000000000000000000000000000000000000'
      case 'eure':
      default:
        return '0xcb444e90d8198415266c6a2724b7900fb12fc56e'
    }
  }

  if (chain === 10) {
    switch (symbol.toLocaleLowerCase()) {
      case 'eth':
        return '0x0000000000000000000000000000000000000000'
      case 'usdc':
      default:
        return '0x0b2c639c533813f4aa9d7837caf62653d097ff85'
    }
  }

  if (chain === 42161) {
    switch (symbol.toLocaleLowerCase()) {
      case 'eth':
        return '0x0000000000000000000000000000000000000000'
      case 'usdc':
      default:
        return '0xaf88d065e77c8cc2239327c5edb3a432268e5831'
    }
  }

  if (chain === 43114) {
    switch (symbol.toLocaleLowerCase()) {
      case 'avax':
        return '0x0000000000000000000000000000000000000000'
      case 'usdc':
      default:
        return '0xb97ef9ef8734c71904d8002f8b6bc66dd9c48a6e'
    }
  }

  // default is polygon
  if (!symbol) {
    return '0x3c499c542cef5e3811e1192ce70d8cc03d5c3359'
    // return '0x2791bca1f2de4661ed88a30c99a7a9449aa84174'
  }
  switch (symbol.toLocaleLowerCase()) {
    case 'matic':
      return '0x0000000000000000000000000000000000000000'
    case 'usdc':
    default:
      return '0x3c499c542cef5e3811e1192ce70d8cc03d5c3359'
    // return '0x2791bca1f2de4661ed88a30c99a7a9449aa84174'
  }
}

export default defineTokenAddressBySymbol