import { Dispatch } from 'redux'
import { defineNetworkName, truncate } from 'helpers'
import * as actions from '../actions'
import { UserActions } from '../types'
import {
  createAlchemyInstance
} from 'helpers'
import { ethers } from 'ethers'
import { CreateLinkActions } from '../../create-link/types'
import { RootState } from 'data/store'
import getERC20TokenData from '../../token/async-actions/get-erc20-token-data'
import { TERC20Token } from 'types'
import { zerionApi } from 'data/api'
import { nativeTokenAddress, nativeTokenAddressAdditional } from 'configs/application'
import getNativeTokenData from '../../token/async-actions/get-native-token-data'
import tokenSymbol from 'images/erc20-token-placeholder.png'

const defineAddress = (
  implementations: {
    address: string;
    chain_id: string;
  }[],
  networkName: string
) => {
  const tokenData = implementations.find(implementation => implementation.chain_id === networkName)
  if (!tokenData) {
    return undefined
  }
  if (tokenData.address === nativeTokenAddressAdditional) {
    return nativeTokenAddress
  }
  return tokenData.address
}

export default async function getUserTokens(
  dispatch: Dispatch<UserActions> & Dispatch<CreateLinkActions>,
  getState: () => RootState
) {
  const {
    user: {
      chainId,
      address,
      provider,
      
    },
  } = getState()

  dispatch(actions.setLoading(true))
  try {

    // check zerion api for address
    const currentNetworkName = defineNetworkName(chainId)
    const networks = await zerionApi.getNetworks()
    if (networks.data.data.find(item => item.id === currentNetworkName)) {
      const zerionItems = await zerionApi.getERC20Item(
        address,
        currentNetworkName
      )

      const zerionData = zerionItems.data.data

      if (zerionData.length > 0) {
        const dataConverted = zerionData.map(item => {
          const decimals = item.attributes.quantity.decimals
          const quantity = item.attributes.quantity.numeric
          const symbol = item.attributes.fungible_info.symbol
          const icon = (item.attributes.fungible_info.icon || {}).url
          
          const address = defineAddress(item.attributes.fungible_info.implementations, currentNetworkName) || nativeTokenAddress
          if (!address) {
            return undefined
          }
          const price = truncate(String(Number(item.attributes.price) * Number(item.attributes.quantity.numeric)), 4, '')

          return {
            tokenBalance: truncate(quantity, 6, ''),
            decimals,
            symbol,
            image: icon || tokenSymbol,
            contractAddress: address,
            price: truncate(String(price), 6, '') 
          }

        }).filter(item => item)
        dispatch(actions.setLoading(false))
        return dataConverted
      }
    } else {
      const alchemy = createAlchemyInstance(chainId)

      const tokens: TERC20Token[] = []

      const nativeTokenData = getNativeTokenData(chainId)
      const nativeTokenAmount = await provider.getBalance(address)
      const nativeTokenAmountFormatted = ethers.utils.formatUnits(
        String(ethers.BigNumber.from(String(nativeTokenAmount))), 
        nativeTokenData.decimals
      )
  
      if (String(nativeTokenAmountFormatted) !== '0.0') {
        tokens.push({
          contractAddress: nativeTokenAddress,
          tokenBalance: truncate(nativeTokenAmountFormatted, 6, ''),
          decimals: nativeTokenData.decimals,
          symbol: nativeTokenData.symbol,
          image: nativeTokenData.image,
          price: '0'
        })
      }
  
      // const tokenSymbol = chainId === 100 ? 'eure' : 'usdc'
  
      // const stableCoinAddress = defineTokenAddressBySymbol(tokenSymbol, chainId)
  
      // const stableCoinData = await getERC20TokenData(
      //   provider,
      //   stableCoinAddress,
      //   chainId as number
      // )
  
      // const stableCoinBalance = 
  
  
  
      if (alchemy) {
        const { tokenBalances }  = await alchemy.core.getTokenBalances(address)
        if (tokenBalances) {
          const getERC20TokensDataPromises = []
  
          for (let x = 0; x < tokenBalances.length; x++) {
            getERC20TokensDataPromises.push(getERC20TokenData(
              provider,
              tokenBalances[x].contractAddress,
              chainId as number
            ))
          }
  
          const tokensData = await Promise.all(getERC20TokensDataPromises)
  
          tokensData.forEach((tokenItem, index) => {
            const amountAtomic = String(ethers.BigNumber.from(String(tokenBalances[index].tokenBalance)))
            if (amountAtomic === '0') {
              return
            }
  
            if (tokenItem.symbol.includes(' ')) {
              return 
            }
  
            const balance = String(ethers.utils.formatUnits(
              String(ethers.BigNumber.from(String(tokenBalances[index].tokenBalance))), 
              tokenItem.decimals
            ))
  
            const hasDivider =  balance.includes('.')
            const dividerPosition = balance.indexOf('.')
            let tokenItemData = {
              ...tokenBalances[index],
              tokenBalance: hasDivider ? truncate(balance, dividerPosition === 5 ? 7 : 6, '') : balance,
              decimals: tokenItem.decimals,
              symbol: tokenItem.symbol,
              image: tokenItem.image,
              price: '0'
            }
            tokens.push(tokenItemData)
          })
  
          dispatch(actions.setLoading(false))
        }
      }
      return tokens
    }

  } catch (err) {
    console.log({ err })
    alert('Error occured with connector update')
  }
  dispatch(actions.setLoading(false))
}
