import { Escrow } from 'abi'
import { ethers } from 'ethers'

const getDeposit = async (
  escrowAddress: string,
  provider: any,
  token: string,
  sender: string,
  transferId: string
) => {
  const escrow = new ethers.Contract(escrowAddress, Escrow.abi, provider)
  const deposit = await escrow.getDeposit(token, sender, transferId)
  return deposit
}

export default getDeposit
