import styled, { css } from "styled-components"
import { TTheme } from 'types'

export const Container = styled.ul`
  margin: 0;
  list-style: none;
  padding: 12px 0;
  width: 100%;
  border-radius: 16px;
  overflow: hidden;
  background-color: ${props => props.theme.primaryBackgroundColor};
  position: relative;
`

export const LoadingOverlay = styled.div`
  position: absolute;
  inset: 0;
  background: rgba(255, 255, 255, .8);
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ButtonListItem = styled.li<{
  currentTheme?: TTheme
}>`
  padding: 16px;
  margin: 0px;
  cursor: pointer;
  align-items: center;
  color: ${props => props.theme.primaryTextColor};
  display: grid;
  grid-template-columns: 32px 1fr min-content;
  grid-template-areas: 'icon title value';
  gap: 12px;

  transition: background-color .3s;

  &:hover {
    background: ${props => props.theme.tagDefaultColor};
  }

  @media (min-width: 769px) {
    border-radius: 8px;
  }
`

export const ItemMain = styled.div`
  grid-area: title;
  text-align: left;
`

export const ItemSecondary = styled.div`
  grid-area: value;
  line-height: 1;
  display: inline;
`

export const ItemTitle = styled.h3`
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  font-size: 500;
  grid-area: title;
`

export const Item = styled.h3`
  font-size: 14px;
  line-height: 20px;
  margin: 0;
`

export const ItemIcon = styled.img`
  width: 32px;
  height: 32px;
  grid-area: icon;
  object-fit: cover;
  object-position: center;
`

export const ItemDescription = styled.p`
  font-size: 11px;
  line-height: 16px;
  margin: 2px 0 0;
  color: ${props => props.theme.additionalTextColor};
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: 'ss01' on;
  letter-spacing: -0.02em;
`

export const ItemValue = styled.p`
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  text-align: right;
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: 'ss01' on;
  letter-spacing: -0.02em;
`

export const ItemStatus = styled.p`
  font-size: 11px;
  line-height: 16px;
  margin: 2px 0 0;
  text-transform: capitalize;
  color: ${props => props.theme.primaryHighlightColor};
`

export const NotFoundTitle = styled.h3`
  margin: 0;
  padding: 0px 16px;
  font-size: 14px;
  color: ${props => props.theme.primaryTextColor};
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const NotFoundIcon = styled.div`
  margin-bottom: 8px;
  svg {
    path {
      stroke: ${props => props.theme.primaryTextColor};
    }
  }
`

export const RecommendedSpan = styled.h3`
  margin: 0;
  font-size: 12px;
  color: ${props => props.theme.additionalTextColor};
  font-weight: 500;
`