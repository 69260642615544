import { FC } from 'react'
import {
  Container,
  Subtitle,
  ButtonContainer,
  ButtonStyled
} from '../styled-components'
import { StatusContainer } from 'components/pages/common'
import { RootState, IAppDispatch } from 'data/store'
import * as createLinkActions from 'data/store/reducers/create-link/actions'
import { CreateLinkActions } from 'data/store/reducers/create-link/types'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { TCreateLinkStep, TLinkStatus } from 'types'
import * as createLinkAsyncActions from 'data/store/reducers/create-link/async-actions'

const mapStateToProps = ({
  user: {
    address,
    provider,
    chainId,
    initialized: userInitialized
  },
  createLink: {
    step,
    tokenAmount,
    escrowAddress,
    humanReadableAmount
  },
  token: {
    name,
    image,
    address: tokenAddress
  },
  link: {
    linkStatus,
    lastTxHash,
    createdAt
  }
}: RootState) => ({
  address,
  step,
  provider,
  chainId,
  createdAt,
  linkStatus,
  userInitialized,
  tokenAddress,
  name,
  tokenAmount,
  escrowAddress,
  humanReadableAmount,
  image,
  lastTxHash
})

const mapDispatcherToProps = (dispatch: Dispatch<CreateLinkActions> & IAppDispatch) => {
  return {
    setStep: (step: TCreateLinkStep) => dispatch(createLinkActions.setStep(step)),
    recoverLink: ( callback?: (transferId: string) => void) => dispatch(createLinkAsyncActions.recoverLink(callback))
  }
}

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatcherToProps>

const LinkStatusScreen: FC<ReduxType> = ({
  address,
  chainId,
  tokenAmount,
  linkStatus,
  recoverLink,
  setStep,
  escrowAddress,
  humanReadableAmount,
  name,
  createdAt,
  lastTxHash
}) => {
  return <Container>
    <StatusContainer
      from={address}
      chainId={chainId as number}
      to={escrowAddress || ''}
      symbol={name}
      amount={String(humanReadableAmount)}
      status={linkStatus as TLinkStatus}
      txHash={lastTxHash || ''}
      created={createdAt as string}
    />

    <ButtonContainer>
      <ButtonStyled
        appearance='action'
        onClick={() => recoverLink((transferId: string) => {
          setStep('link_created')
        })}
      >
        See claim link
      </ButtonStyled>
    </ButtonContainer>
    
  </Container>
}

export default connect(mapStateToProps, mapDispatcherToProps)(LinkStatusScreen)