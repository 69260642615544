import styled, { css } from 'styled-components'
import ButtonComponent from 'components/common/button'

export const Container = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
  max-width: 572px;
`

export const Subtitle = styled.h2`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.primaryTextColor};
  margin: 0 0 12px;
`

export const ButtonContainer = styled.div<{
  vertical?: boolean
}>`
  display: flex;
  gap: 10px;
  width: 100%;
  padding-top: 20px;

  ${props => props.vertical && css`
    flex-direction: column;
  `}
`

export const ButtonStyled = styled(ButtonComponent)`
  flex: 1;
`
