import { THistoryItem } from 'types'
import { action } from 'typesafe-actions'
import { Constants } from './constants'

export function setItems(items: THistoryItem[]) {
  return action(
    Constants.HISTORY_SET_ITEMS,
    {
      items
    }
  )
}

export function setTotal(total: number) {
  return action(
    Constants.HISTORY_SET_TOTAL,
    {
      total
    }
  )
}

export function setLoading(loading: boolean) {
  return action(
    Constants.HISTORY_SET_LOADING,
    {
      loading
    }
  )
}

