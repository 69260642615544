import { CreateLinkState, CreateLinkActions } from './types'
import { Constants } from './constants'
import { BigNumber } from 'ethers'

const initialState: CreateLinkState = {
  loading: false,
  step: 'initial',
  initialized: false,
  tokenAmount: BigNumber.from(0),
  humanReadableAmount: '0',
  transferId: null,
  maxTransferLimit: BigNumber.from(0),
  minTransferLimit: BigNumber.from(0),
  approveTxHash: null,
  depositTxHash: null,
  escrowAddress: null,
  isDepositWithAuthAvailable: false,
  recommendedWalletApp: null,
  feeToken: null,
  feeAmount: '0',
  humanReadableFeeAmount: '0',
  feeTokenSymbol: null,
  tokenDataInitialized: false
}

export function createLinkReducer(
  state: CreateLinkState = initialState,
  action:CreateLinkActions
): CreateLinkState {
    switch (action.type) {
      case Constants.CREATE_LINK_SET_LOADING:
        return {...state, loading: action.payload.loading }

      case Constants.CREATE_LINK_SET_RECOMMENDED_WALLET:
        return {...state, recommendedWalletApp: action.payload.recommendedWalletApp }
      case Constants.CREATE_LINK_SET_STEP:
        return {...state, step: action.payload.step }

      case Constants.CREATE_LINK_SET_TOKEN_DATA_INITIALIZED:
        return {...state, tokenDataInitialized: action.payload.tokenDataInitialized }

      case Constants.CREATE_LINK_SET_FEE_TOKEN_SYMBOL:
        return {...state, feeTokenSymbol: action.payload.feeTokenSymbol }

      case Constants.CREATE_LINK_SET_FEE_TOKEN:
        return {...state, feeToken: action.payload.feeToken }
      case Constants.CREATE_LINK_SET_FEE_AMOUNT:
        return {...state, feeAmount: action.payload.feeAmount }
      case Constants.CREATE_LINK_SET_HUMAN_READABLE_FEE_AMOUNT:
        return {...state, humanReadableFeeAmount: action.payload.humanReadableFeeAmount }

      case Constants.CREATE_LINK_SET_INITIALIZED:
        return {...state, initialized: action.payload.initialized }

      case Constants.CREATE_LINK_SET_AMOUNT:
        return {...state, tokenAmount: action.payload.tokenAmount }
      case Constants.CREATE_LINK_SET_IS_DEPOSIT_WITH_AUTH_AVAILABLE:
        return {...state, isDepositWithAuthAvailable: action.payload.isDepositWithAuthAvailable }
      case Constants.CREATE_LINK_SET_HUMAN_READABLE_AMOUNT:
        return {...state, humanReadableAmount: action.payload.humanReadableAmount }
      case Constants.CREATE_LINK_SET_TRANSFER_ID:
        return {...state, transferId: action.payload.transferId }
      case Constants.CREATE_LINK_SET_MAX_TRANSFER_LIMIT:
        return {...state, maxTransferLimit: action.payload.value }
      case Constants.CREATE_LINK_SET_DEPOSIT_TX_HASH:
        return {...state, depositTxHash: action.payload.depositTxHash }
      case Constants.CREATE_LINK_SET_ESCROW_ADDRESS:
        return {...state, escrowAddress: action.payload.escrowAddress }
      case Constants.CREATE_LINK_SET_APPROVE_TX_HASH:
        return {...state, approveTxHash: action.payload.approveTxHash }
      case Constants.CREATE_LINK_SET_MIN_TRANSFER_LIMIT:
        return {...state, minTransferLimit: action.payload.value }
      default:
          return state;
    }
}