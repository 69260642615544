import { FC, useEffect } from 'react'
import { RootState } from 'data/store'
import { connect } from 'react-redux'
import { 
  TitleComponent,
  ButtonsContainer,
  TokenImageLarge,
  TokenImageContainer,
  DoneIcon,
  Subtitle,
  DoneIconERC20,
  UserAddress
} from './styled-components'
import  { Content } from '../styled-components'
import {
  ERC20TokenPreview,
  ClaimingFinishedButton,
  PoweredByFooter,
  ClaimingFinishedSecondaryButton
} from 'components/pages/common'
import ClaimingFinishedERC20 from 'images/claiming-finished-erc20.png'
import { TTokenType } from 'types'

const mapStateToProps = ({
  token: {
    image,
    name,
    decimals,
    tokenType,
    amount,
    address
  },
  user: {
    email
  },
  link: {
    chainId
  }
}: RootState) => ({
  image,
  name,
  tokenType,
  email,
  amount,
  decimals,
  chainId,
  address
})

type ReduxType = ReturnType<typeof mapStateToProps>


const defineTitle = (type: TTokenType | null, email?: string) => {
  if (email) {
    return <>You can access your NFT by logging in to Crossmint with <UserAddress>{email}</UserAddress></>
  }
  return `Your ${type === 'ERC20' ? 'tokens' : 'NFT'} will appear in your account in a few minutes`
}

const ClaimingFinished: FC<ReduxType> = ({
  image,
  name,
  tokenType,
  amount,
  decimals,
  email,
  chainId,
  address
}) => {

  const content = tokenType === 'ERC20' || tokenType === 'NATIVE' ? <ERC20TokenPreview
    name={name}
    image={image as string}
    amount={String(amount)}
    decimals={decimals}
    status='finished'
    chainId={chainId}
  /> : <>
    {image && <TokenImageContainer>
      <DoneIcon />
      <TokenImageLarge
        src={image}
        alt={name}
      />
    </TokenImageContainer>}
  </>

  return <Content>
    {content}
    <TitleComponent>
      {(tokenType === 'ERC20' || tokenType === 'NATIVE') && <DoneIconERC20 src={ClaimingFinishedERC20} />}
        Successfully claimed
      </TitleComponent>
    <Subtitle>
      {defineTitle(
        tokenType,
        email
      )}
    </Subtitle>
    <ButtonsContainer>
      <ClaimingFinishedButton />
      <ClaimingFinishedSecondaryButton />
    </ButtonsContainer>
    <PoweredByFooter />
  </Content>
}

export default connect(mapStateToProps)(ClaimingFinished)