import styled, { css } from "styled-components"
import { TProps } from "./types"
import { TTheme } from 'types'

export const HeaderNetworkWrapper = styled.div<TProps & {
  currentTheme?: TTheme
}>`
  width: 36px;
  height: 36px;
  margin-left: auto;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  border-radius: 36px;
  border: 1px solid ${props => props.theme.primaryBorderColor};
  background: ${props => props.theme.primaryBackgroundColor};
  color: ${props => props.theme.primaryTextColor};

  ${props => props.enableChainName && css`
    width: auto;
  `}
  
`

export const HeaderNetwork = styled.img`
  display: block;
  max-width: 16px;
`

export const NetworkIndicatorClass = 'NetworkIndicatorClass'

export const MiniPopupCustomItem = styled.div<{
  active?: boolean,
  currentTheme?: TTheme
  }>`
  font-size: 12px;
  min-height: 36px;
  margin-bottom: 0px;
  text-align: left;
  cursor: pointer;
  padding: 0 20px;
  display: grid;
  grid-template-columns: min-content 1fr;
  gap: 8px;
  align-items: center;
  width: 100%;
  color: ${props => props.theme.primaryTextColor};
  &:last-child {
    margin-bottom: 0px;
  }

  &:hover {
    background-color: ${props => props.theme.tagDefaultColor};
    
  }

  ${props => props.active && css`
    background-color: ${props => props.theme.tagDefaultColor};
    
  `}
`

export const MiniPopupDivider = styled.hr`
  margin: 0;
  padding: 0;
  border-left: 0;
  border-bottom: 0;
  border-right: 0;
  border-top: 1px solid ${props => props.theme.primaryBorderColor};
`


export const HeaderChainName = styled.span`
  margin-left: 8px;
  font-size: 14px;
`

export const LogoutIcon = styled.div`
  height: auto;
  display: flex;
  align-items: center;

  svg {
    path {
      stroke: ${props => props.theme.primaryHighlightColor};
    }
  }
`