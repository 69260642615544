import { FC } from 'react'
import Icons from 'icons'
import { TooltipContainer, TooltipWindow } from './styled-components'
import { TProps } from './types'

const Tooltip: FC<TProps> = ({
  position = 'right',
  content
}) => {
  return <TooltipContainer>
    <Icons.TooltipIcon />

    <TooltipWindow position={position}>
      {content}
    </TooltipWindow>
  </TooltipContainer>
}

export default Tooltip