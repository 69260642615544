import { LinkState, LinkActions } from './types'
import { Constants } from './constants'

const initialState: LinkState = {
  step: 'loading',
  loading: false,
  chainId: null,
  hash: null,
  title: null,
  error: null,
  expirationTime: null,
  isManual: false,
  version: null,
  weiAmount: null,
  isClaimed: false,
  theme: 'dark',
  addressIsManuallySet: false,
  linkStatus: null,
  createdAt: null,
  lastTxHash: null,
  transferId: null,
  escrowAddress: null,
  initialized: false,
  recommendedWalletApp: null
}

export function linkReducer(
  state: LinkState = initialState,
  action: LinkActions
): LinkState {
  switch (action.type) {
    case Constants.LINK_SET_STEP:
      return {...state, step: action.payload.step }
    case Constants.LINK_SET_LINK_STATUS:
      return {...state, linkStatus: action.payload.linkStatus }
    case Constants.LINK_SET_CREATED_AT:
      return {...state, createdAt: action.payload.createdAt }
    case Constants.LINK_SET_CHAIN_ID:
      return {...state, chainId: action.payload.chainId }
    case Constants.LINK_SET_TITLE:
        return {...state, title: action.payload.title }
    case Constants.LINK_SET_HASH:
      return {...state, hash: action.payload.hash }
    case Constants.LINK_SET_INITIALIZED:
      return {...state, initialized: action.payload.initialized }
    case Constants.LINK_SET_LAST_TX_HASH:
      return {...state, lastTxHash: action.payload.lastTxHash }
    case Constants.LINK_SET_IS_MANUAL:
      return {...state, isManual: action.payload.isManual }
    case Constants.LINK_SET_TRANSFER_ID:
      return {...state, transferId: action.payload.transferId }
    case Constants.LINK_SET_ESCROW_ADDRESS:
    return {...state, transferId: action.payload.escrowAddress }
    case Constants.LINK_SET_EXPIRATION_TIME:
      return {...state, expirationTime: action.payload.expirationTime }
    case Constants.LINK_SET_WEI_AMOUNT:
      return {...state, weiAmount: action.payload.weiAmount }
    case Constants.LINK_SET_IS_CLAIMED:
      return {...state, isClaimed: action.payload.isClaimed }
    case Constants.LINK_SET_LOADING:
      return {...state, loading: action.payload.loading }
    case Constants.LINK_SET_ERROR:
      return {...state, error: action.payload.error }
    case Constants.LINK_SET_ADDRESS_MANUALLY_SET:
      return {...state, addressIsManuallySet: action.payload.addressIsManuallySet }
    case Constants.LINK_SET_RECOMMENDED_WALLET:
      return {...state, recommendedWalletApp: action.payload.recommendedWalletApp }

    case Constants.LINK_SET_ADDRESS_MANUALLY_SET:
      return {...state, addressIsManuallySet: action.payload.addressIsManuallySet }
    case Constants.LINK_SET_THEME:
      return {...state, theme: action.payload.theme }
    default:
        return state;
  }
}