import { FC } from 'react'
import Icons from 'icons'
import {
  IntroImage,
  PoweredByFooterStyled,
  Content,
  Container,
  ButtonStyled,
  Subtitle,
  TitleStyled,
  LoaderContainer,
  TokenImage
} from './styled-components'
import LoaderComponent from 'components/common/loader'
import { TProps } from './types'

const SetConnectorScreen: FC<TProps> = ({
  loading,
  onClick,
  tokenSymbol,
  tokenDataInitialized,
  tokenImage
}) => {
  return <Container>
    {!tokenDataInitialized && <LoaderContainer>
      <LoaderComponent />
    </LoaderContainer>}
    <IntroImage>
      <Icons.IntroImage />
    </IntroImage>
    <Content>
      <TitleStyled>Share <span>{tokenImage && <TokenImage alt={tokenSymbol} src={tokenImage} />} {tokenSymbol}</span></TitleStyled>
      <Subtitle>Sending <span>{tokenSymbol}</span> is now as easy as sending a text. You can share a link to any phone number or email. If it's not claimed in 2 weeks, we'll return funds</Subtitle>
    </Content>
    
    <PoweredByFooterStyled />

    <ButtonStyled
      loading={loading}
      disabled={loading}
      appearance='action'
      onClick={onClick}
    >
      Connect Wallet
    </ButtonStyled>
  </Container>
}

export default SetConnectorScreen