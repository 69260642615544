import * as actions from '../actions'
import { Dispatch } from 'redux'
import { TokenActions } from '../types'
import { ethers, BigNumber } from 'ethers'
import { ERC20Contract } from 'abi'
import { nativeTokenAddress } from 'configs/application'

export type TGetTokenBalance = (
  dispatch: Dispatch<TokenActions>,
  address: string,
  tokenAddress: string,
  provider: any
) => Promise<boolean>


const getTokenBalance: TGetTokenBalance = async (
  dispatch,
  address,
  tokenAddress,
  provider
) => {
  try {
    if (tokenAddress === nativeTokenAddress) {
      const tokenAmount = await provider.getBalance(address)
      dispatch(actions.setBalance(tokenAmount))
      return true
    } else {

      const contractInstance = new ethers.Contract(tokenAddress, ERC20Contract, provider)
      const tokenAmount = await contractInstance.balanceOf(address)
      dispatch(actions.setBalance(tokenAmount))
      return true
    }
    
  } catch (err) {
    console.log({
      err
    })
    return false
  }
}

export default getTokenBalance