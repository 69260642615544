
import { Dispatch } from 'redux'
import * as actionsCreateLink from '../actions'
import * as asyncActionsToken from '../../token/async-actions'
import * as actionsToken from '../../token/actions'
import { CreateLinkActions } from '../types'
import { TokenActions } from '../../token/types'
import { UserActions } from '../../user/types'
import { RootState } from 'data/store'
import { alertError } from 'helpers'
import { ethers } from 'ethers'
import { TTokenType } from 'types'
import { defineJSONRpcUrl } from 'helpers'
import { nativeTokenAddress } from 'configs/application'
const { REACT_APP_INFURA_ID } = process.env

export default function getTokenData(
  chainId: number,
  tokenAddress: string
) {
  return async (
    dispatch: Dispatch<CreateLinkActions> & Dispatch<TokenActions> & Dispatch<UserActions>,
    getState: () => RootState
  ) => {
    dispatch(actionsCreateLink.setLoading(true))

    try {
      dispatch(actionsToken.setAddress(tokenAddress))
      const tokenType = tokenAddress !== nativeTokenAddress ? 'ERC20' : 'NATIVE'
      dispatch(actionsToken.setTokenType(tokenType as TTokenType))
      if (
        tokenAddress &&
        chainId
      ) {
        const jsonRpcUrl = defineJSONRpcUrl({ chainId, infuraPk: REACT_APP_INFURA_ID as string })
        const provider = new ethers.providers.JsonRpcProvider(jsonRpcUrl)

        // @ts-ignore
        await asyncActionsToken.getTokenData(
          dispatch,
          getState,
          provider,
          chainId
        )

        dispatch(actionsCreateLink.setTokenDataInitialized(true))
      }
    } catch (
      error: any
    ) {
      alertError('Some error occured, please check console for more information')
      console.log(error, error.statusCode)
    }

    dispatch(actionsCreateLink.setLoading(false))
  }
}
