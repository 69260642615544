
import { Dispatch } from 'redux';
import { LinkActions } from '../types'
import { ethers, BigNumber } from 'ethers'
import { RootState } from 'data/store'
import contracts from 'configs/contracts'
import { signReceiverAddress } from '@linkdrop/contracts/scripts/utils.js'
import * as dropActions from '../actions'
import * as userActions from '../../user/actions'
import { UserActions } from '../../user/types'
import { resolveENS, defineJSONRpcUrl, handleClaimResponseError } from 'helpers'
import { AxiosError } from 'axios'
import gasPriceLimits from 'configs/gas-price-limits'
import { plausibleApi } from 'data/api'
const { REACT_APP_INFURA_ID = '' } = process.env

export default function claimERC1155(
  manualAddress?: string,
  checkGasPrice?: boolean
) {
  return async (
    dispatch: Dispatch<LinkActions> & Dispatch<UserActions>,
    getState: () => RootState
  ) => {
    dispatch(dropActions.setLoading(true))
    let {
      user: {
        sdk,
        signer,
        address,
        provider,
        email,
        claimLink
      },
      token: {
        address: tokenAddress,
        amount,
        tokenId
      },
      link: {
        isManual,
        recommendedWalletApp,
        weiAmount,
        expirationTime,
        chainId
      }
    } = getState()
    if (!chainId) {
      dispatch(dropActions.setLoading(false))
      return alert(`chainId is not provided`)
    }

    if (!tokenAddress) {
      dispatch(dropActions.setLoading(false))
      return alert(`tokenAddress is not provided`)
    }

    if (!tokenId) {
      dispatch(dropActions.setLoading(false))
      return alert(`tokenId is not provided`)
    }

    if (!expirationTime) {
      dispatch(dropActions.setLoading(false))
      return alert(`expirationTime is not provided`)
    }

    if (!amount) {
      dispatch(dropActions.setLoading(false))
      return alert(`amount is not provided`)
    }

    if (!address) {
      if (manualAddress) {
        const jsonRpcUrl = defineJSONRpcUrl({ chainId: 1, infuraPk: REACT_APP_INFURA_ID })
        const provider = new ethers.providers.JsonRpcProvider(jsonRpcUrl)

        const addressResolved = await resolveENS(manualAddress, provider)

        if (addressResolved) {
          dispatch(userActions.setAddress(addressResolved))
          address = addressResolved
          dispatch(dropActions.setAddressIsManuallySet(true))
        } else if (!window.navigator.onLine) {
          dispatch(dropActions.setLoading(false))
          // plausibleApi.invokeEvent({
          //   eventName: 'error',
          //   data: {
          //     err_name: 'error_no_connection',
          //     campaignId
          //   }
          // })
          return dispatch(dropActions.setStep('error_no_connection'))
        } else {
          dispatch(dropActions.setLoading(false))
          return alert('Provided address or ens is not correct')
        }
      } else if (email) {
        alert('No user address provided for claim')
      }
    }


    try {
        const txHash = await claimLink?.redeem(address)

        if (txHash) {
          dispatch(dropActions.setHash(txHash))
          dispatch(dropActions.setStep('claiming_process'))
        }
      
    } catch (error: any | AxiosError) {
      // handleClaimResponseError(dispatch, error)
    }
    dispatch(dropActions.setLoading(false))
  } 
}
