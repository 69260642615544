
import { RootState, IAppDispatch } from 'data/store'
import * as actionCreateLink from '../../create-link/actions'
import * as actionsUser from '../../user/actions'
import * as actionsToken from '../../token/actions'
import * as actionsLink from '../../link/actions'
import * as actionsAsyncTokens from '../../token/async-actions'

import { ethers, BigNumber } from 'ethers'
import { alertError } from 'helpers'
import { Dispatch } from 'redux'
import { CreateLinkActions } from '../types'
import { UserActions } from '../../user/types'
import { TokenActions } from '../../token/types'
import { LinkActions } from '../../link/types'

export default function getLinkStatus(
  transferId: string,
  callback?: (location: string) => void
) {
  // @ts-ignore
  return async (
    dispatch: Dispatch<CreateLinkActions> &
              IAppDispatch &
              Dispatch<UserActions> &
              Dispatch<TokenActions> &
              Dispatch<LinkActions>,
    getState: () => RootState
  ) => {
    dispatch(actionCreateLink.setLoading(true))

    const {
      user: {
        sdk,
        address,
        chainId,
        provider
      }
      // @ts-ignore
    } = getState()

    if (!sdk) {
      return alertError('SDK is not provided')
    }

    if (!address) {
      return alertError('address is not provided')
    }


    if (!chainId) {
      return alertError('chainId is not provided')
    }

    try {
      const claimLink = await sdk?.retrieveClaimLink({
        transferId,
        chainId: chainId as number
      })

      if (claimLink) {
        dispatch(actionsUser.setClaimLink(claimLink))
        const {
          amount,
          escrowAddress,
          operations,
          status,
          token,
          chainId,
          tokenType
        } = claimLink
        dispatch(actionsToken.setTokenType(tokenType))
        dispatch(actionsToken.setAddress(token))
        await actionsAsyncTokens.getTokenData(
          dispatch,
          getState,
          provider,
          chainId
        ) 
        const {
          token: {
            decimals
          }
          // @ts-ignore
        } = getState()

        const lastOperation = operations[operations.length - 1]
        const firstOperation = operations[0]
        const humanReadableAmount = ethers.utils.formatUnits(amount, decimals)
        dispatch(actionsToken.setAmount(BigNumber.from(amount)))
        dispatch(actionCreateLink.setHumanReadableAmount(humanReadableAmount))
        dispatch(actionCreateLink.setEscrowAddress(escrowAddress as string))
        dispatch(actionsLink.setLinkStatus(status))

        // @ts-ignore
        if (lastOperation && lastOperation.txHash) {
          // @ts-ignore
          dispatch(actionsLink.setLastTxHash((lastOperation.txHash || '') as string))
        }

        if (firstOperation && firstOperation.timestamp) {
          dispatch(actionsLink.setCreatedAt(firstOperation.timestamp))
        }

        if (status === 'deposited' || status === 'created') {
          callback && callback(`/create/${chainId}/${token}`)
          dispatch(actionCreateLink.setStep('link_status'))
        }
      }
    } catch (
      error: any
    ) {
      alertError('Some error occured, please check console for more information')
      console.log(error, error.statusCode)
      // dispatch(actionsLink.setTransferStatus('error'))
    }
    dispatch(actionCreateLink.setLoading(false))
  }
}
