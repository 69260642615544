
import { Dispatch } from 'redux'
import * as actionsUser from '../../user/actions'
import { UserActions } from '../../user/types'
import { TWalletName } from 'types'

export default function deeplinkRedirect(
  deeplink: string,
  walletId: TWalletName,
  redirectCallback: () => void
) {
  return async (
    dispatch: Dispatch<UserActions> 
  ) => {
    dispatch(actionsUser.setWalletApp(walletId))
    const link = document.createElement('a')
    link.href = deeplink
    link.target = '_blank'
    link.click()
    redirectCallback && redirectCallback()
  }
}
