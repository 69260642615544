import { FC } from 'react'
import { ButtonStyled } from './styled-components'
import { plausibleApi } from 'data/api'
import { RootState } from 'data/store'
import { connect } from 'react-redux'
import { useQuery } from 'hooks'

const mapStateToProps = ({
  link: {
    chainId
  },
  token: {
    address: tokenAddress,
    name
  }
}: RootState) => ({
  chainId,
  tokenAddress,
  name
})

type ReduxType = ReturnType<typeof mapStateToProps>

const ClaimingFinishedButton: FC<ReduxType> = ({
  tokenAddress,
  chainId,
  name
}) => {
  const queryParams = useQuery()

  if (
    queryParams.get('r')
  ) {
    return <ButtonStyled
      onClick={() => {
        plausibleApi.invokeEvent({
          eventName: 'claim_finished_button_clicked',
          data: {
            tokenAddress: String(tokenAddress),
            chainId: String(chainId)
          }
        })
        window.open(decodeURIComponent(queryParams.get('r') as string), '_blank')
      }}
      appearance='action'
    >
      About {name}
    </ButtonStyled>
  }

  return null
}

export default connect(mapStateToProps)(ClaimingFinishedButton)
