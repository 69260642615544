import { HistoryState, HistoryActions } from './types'
import { Constants } from './constants'

const initialState: HistoryState = {
  total: 0,
  items: [],
  loading: false
};

export function historyReducer(
  state: HistoryState = initialState,
  action: HistoryActions
): HistoryState {
  switch (action.type) {
    case Constants.HISTORY_SET_TOTAL:
      return { ...state, total: action.payload.total }
    case Constants.HISTORY_SET_ITEMS:
      return {...state, items: action.payload.items }
    case Constants.HISTORY_SET_LOADING:
      return {...state, loading: action.payload.loading }
    default:
      return state;
  }
}