import { FC } from 'react'
import { TClaimLinkStep } from 'types'
import { RootState } from 'data/store'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { LinkActions } from 'data/store/reducers/link/types'
import * as linkActions from 'data/store/reducers/link/actions'
import { ERC20TokenPreview } from 'components/pages/common'
import {
  TitleComponent,
  ScreenButton,
  TextComponent,
  TokenImageContainer,
  UserAddress,
  PoweredByFooterStyled
} from './styled-components'

import { Content } from '../styled-components'

const mapStateToProps = ({
  createLink: { loading },
  user: { loading: userLoading },
  link: {
    chainId
  },
  token: {
    amount,
    address,
    name,
    image,
    decimals
  }
}: RootState) => ({
  loading,
  userLoading,
  amount,
  address,
  name,
  image,
  decimals,
  chainId
})

const mapDispatcherToProps = (dispatch: Dispatch<LinkActions>) => {
  return {
    setStep: (step: TClaimLinkStep) => dispatch(linkActions.setStep(step))
  }
}

type ReduxType = ReturnType<typeof mapDispatcherToProps> & ReturnType<typeof mapStateToProps>

const SetConnector: FC<ReduxType> = ({
  setStep,
  loading,
  userLoading,
  address,
  amount,
  name,
  image,
  decimals,
  chainId
}) => {

  return <Content> 
    <ERC20TokenPreview
      name={name}
      image={image as string}
      amount={String(amount)}
      decimals={decimals}
      status='initial'
      chainId={chainId}
    />
    <TitleComponent>
      You can claim tokens
    </TitleComponent>
    <TextComponent>
      Connect your Wallet App or download it from the App Store if you don’t have it yet to proceed claiming tokens
    </TextComponent>
    <PoweredByFooterStyled />
    <ScreenButton
      appearance='action'
      onClick={() => {
        setStep('wallets_list')
      }}
    >
    Claim
    </ScreenButton>
  </Content>



}

export default connect(mapStateToProps, mapDispatcherToProps)(SetConnector)