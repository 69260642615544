import { ERC20Contract } from 'abi'
import { ethers } from 'ethers'
import tokenSymbol from 'images/erc20-token-placeholder.png'
import { alertError, createAlchemyInstance } from 'helpers'
import { TERC20Token } from 'types'
import { RootState, IAppDispatch } from 'data/store'
import { TokenActions } from '../../token/types'
import { UserActions } from '../../user/types'
import { CreateLinkActions } from '../../create-link/types'
import { Dispatch } from 'redux'
import * as actionsUser from '../../user/actions'
import * as actionCreateLink from '../../create-link/actions'

const getERC20TokenDataByAddress = (
  tokenAddress: string,
  callback?: () => void
) => {
  return async (
    dispatch:  Dispatch<CreateLinkActions> & Dispatch<TokenActions> & Dispatch<UserActions> & IAppDispatch,
    getState: () => RootState
  ) => {
    try {
      const {
        user: {
          provider,
          address,
          erc20Tokens
        }
      } = getState()
      const contractInstance = new ethers.Contract(tokenAddress, ERC20Contract, provider)
      const balance = await contractInstance.balanceOf(address)
      if (String(balance) === '0') {
        return alert('No token presented')
      }
      // let symbol = await contractInstance.symbol()
      // let decimals = await contractInstance.decimals()
      // const image = tokenSymbol
      
      // const token = {
      //   contractAddress: tokenAddress,
      //   decimals,
      //   image,
      //   symbol,
      //   tokenBalance: balance
      // }

      // const tokens = [
      //   ...erc20Tokens,
      //   token
      // ]

      // dispatch(actionsUser.setERC20Tokens(tokens as TERC20Token[]))
      callback && callback()
      dispatch(actionCreateLink.setStep('set_amount'))

    } catch (err) {
      alertError('Some error occured. Please check console')
      console.error(err)
    }
    
    
  }
}

export default getERC20TokenDataByAddress