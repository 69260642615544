import styled, { css } from "styled-components"
import { TTheme } from 'types'

export const TokenContainer = styled.div<{
  currentTheme?: TTheme
}>`
  cursor: pointer;
  font-size: 14px;
  display: flex;
  width: min-content;
  line-height: 1;
  align-items: center;
  border-radius: 18px;
  border: 1px solid ${props => props.theme.primaryBorderColor};
  padding: 10px 16px;
  margin-right: 10px;

  transition: border .3s, background-color .3s;

  &:hover {
    ${props => {
      return css`
        border-color: transparent;
        background-color: ${props => props.theme.tagDefaultColor};
      `
    }};
  }
`

export const TokenImage = styled.img`
  margin-right: 8px;
  max-width: 16px;
  border-radius: 16px;
`

export const QuickAccess = styled.div`
  margin-bottom: 12px;
  width: 100%;
  display: flex;
  align-items: center;
  color: ${props => props.theme.primaryTextColor};
`