import { FC, useState, useEffect } from 'react'
import { RootState, IAppDispatch } from 'data/store'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { CreateLinkActions } from 'data/store/reducers/create-link/types'
import { TokenActions } from 'data/store/reducers/token/types'
import { ButtonList, OriginalInput } from 'components/common'
import {
  Container
} from '../styled-components'
import {
  TokenContainer,
  TokenImage,
  QuickAccess
} from './styled-components'
import * as createLinkAsyncActions from 'data/store/reducers/create-link/async-actions'
import * as tokenAsyncActions from 'data/store/reducers/token/async-actions'
import { useHistory } from 'react-router-dom'
import { nativeTokenAddress } from 'configs/application'
import getNativeTokenData from 'data/store/reducers/token/async-actions/get-native-token-data'
import {
  defineTokenAddressBySymbol,
  defineCreateLinkRedirectURL
} from 'helpers'
import {
  TWalletName
} from 'types'
import USDCIcon from 'images/usdc.png'
import { useQuery } from 'hooks'

const mapStateToProps = ({
  createLink: { loading },
  user: { loading: userLoading, erc20Tokens, chainId }
}: RootState) => ({
  loading,
  userLoading,
  erc20Tokens,
  chainId
})

const mapDispatcherToProps = (dispatch: Dispatch<CreateLinkActions> & Dispatch<TokenActions> & IAppDispatch) => {
  return {
    updateTokenToSend: (
      callback: () => void
    ) => dispatch(createLinkAsyncActions.updateTokenToSend(
      callback
    )),
    getERC20TokenDataByAddress: (
      address: string,
      callback: () => void
    ) => {
      dispatch(tokenAsyncActions.getERC20TokenDataByAddress(
        address,
        callback
      ))
    }
  }
}

const defineStableCoin = (
  chainId: number
) => {
  if (chainId === 100) {
    const address = defineTokenAddressBySymbol('eure', chainId)
    return {
      symbol: 'EURe', image: USDCIcon, address
    }
  }
  const address = defineTokenAddressBySymbol('usdc', chainId)
  return {
    symbol: 'USDC', image: USDCIcon, address
  }
}

// @ts-ignore
type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatcherToProps>

const ChooseAssetScreen: FC<ReduxType> = ({
  erc20Tokens,
  updateTokenToSend,
  chainId,
  getERC20TokenDataByAddress
}) => {
  const query = useQuery()

  const nativeTokenData = getNativeTokenData(chainId)
  const stableCoin = defineStableCoin(chainId as number)
  const [ value, setValue ] = useState<string>('')
  const history = useHistory()
  const currentTheme = query.get('theme') === 'light' ? 'light' : 'dark'

  const buttonListItems = erc20Tokens.map(item => {
    if (value !== '') {
      if (
        !item.symbol?.toLowerCase().includes(value.toLowerCase()) &&
        !item.contractAddress.toLowerCase().includes(value.toLowerCase())
      ) {
        return undefined
      }
    }

    return {
      title: item.symbol as string,
      value: `${item.price}$`,
      description: item.tokenBalance,
      icon: item.image as string,
      onClick: () => {
        const reirectUrl = defineCreateLinkRedirectURL(
          query.get('amount') || '0',
          (query.get('w') as TWalletName) || 'metamask',
          query.get('theme') || 'light',
          chainId as number,
          item.contractAddress,
          query.get('r'),
          query.get('tokens_list') || 'true',
          query.get('limits') || 'true'
        )
        updateTokenToSend(
          history.push(reirectUrl)
        )
      }
    }
  }).filter(item => item)

  useEffect(() => {

    if (
      value.length === 42 &&
      value.startsWith('0x') &&
      buttonListItems.length === 0
    ) {
      const reirectUrl = defineCreateLinkRedirectURL(
        query.get('amount') || '0',
        (query.get('w') as TWalletName) || 'metamask',
        query.get('theme') || 'light',
        chainId as number,
        value,
        query.get('r'),
        query.get('tokens_list') || 'true',
        query.get('limits') || 'true'
      )

      getERC20TokenDataByAddress(
        value,
        () => history.push(reirectUrl)
      )
    }
  }, [value, buttonListItems])

  return <Container>
    <OriginalInput
      value={value}
      placeholder='Search'
      onChange={(value) => {
        setValue(value)
        return value
      }}
    />
    <QuickAccess>
      <TokenContainer
        currentTheme={currentTheme}
        onClick={
        () => {
          const reirectUrl = defineCreateLinkRedirectURL(
            query.get('amount') || '0',
            (query.get('w') as TWalletName) || 'metamask',
            query.get('theme') || 'light',
            chainId as number,
            nativeTokenAddress,
            query.get('r'),
            query.get('tokens_list') || 'true',
            query.get('limits') || 'true'
          )
          updateTokenToSend(
            history.push(reirectUrl)
          )
        }
      }>
        <TokenImage src={nativeTokenData.image} /> {nativeTokenData.symbol}
      </TokenContainer>

      <TokenContainer
        currentTheme={currentTheme}
        onClick={
        () => {
          const reirectUrl = defineCreateLinkRedirectURL(
            query.get('amount') || '0',
            (query.get('w') as TWalletName) || 'metamask',
            query.get('theme') || 'light',
            chainId as number,
            stableCoin.address,
            query.get('r'),
            query.get('tokens_list') || 'true',
            query.get('limits') || 'true'
          )
          updateTokenToSend(
            history.push(reirectUrl)
          )
        }
      }>
        <TokenImage src={stableCoin.image} /> {stableCoin.symbol}
      </TokenContainer>
    </QuickAccess>
    
    <ButtonList items={buttonListItems} notFoundTitle='No assets found' />
  </Container>
}

export default connect(mapStateToProps, mapDispatcherToProps)(ChooseAssetScreen)