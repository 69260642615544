import { FC } from 'react'
import {
  InputField
} from './styled-components'
import { IProps } from './types'

const InputOriginalComponent: FC<IProps> = ({
  placeholder,
  disabled = false,
  type = 'text',
  onChange,
  error,
  name,
  value = '',
  className,
  refProp,
  onFocus,
  onBlur,
  pattern,
  inputMode,
  onKeyPress
}) => {
  return (
    <InputField
      ref={refProp}
      className={className}
      name={name}
      error={error}
      disabled={disabled}
      onFocus={onFocus}
      onKeyPress={onKeyPress}
      onBlur={onBlur}
      onChange={(evt) => onChange(evt.target.value)}
      type={type}
      placeholder={placeholder}
      value={value}
      pattern={pattern}
      inputMode={inputMode}
    />
  )
}

export default InputOriginalComponent
