import { FC } from 'react'
import { 
  Container,
  GridLabelUppercase
} from './styled-components'
import { Link } from 'components/common'
import {
  GridTable,
  GridLabel,
  GridValue,
  GridDivider
} from 'components/pages/common'
import TProps from './types'
import {
  shortenString,
  defineRealNetworkName,
  defineExplorerURL,
  formatDate,
  removeProtocol
} from 'helpers'

const StatusContainer: FC<TProps> = ({
  amount,
  from,
  to,
  txHash,
  chainId,
  status,
  created,
  symbol
}) => {
  const networkName = defineRealNetworkName(chainId)
  const explorerUrl = defineExplorerURL(chainId)
  const txHashUrl = `${explorerUrl}/tx/${txHash}`
  const txHashTitle = removeProtocol(`${explorerUrl}/tx/${shortenString(txHash, 2)}`)
  return <Container>
    <GridTable>
      <GridLabelUppercase>
        {status}
      </GridLabelUppercase>
      <GridValue>
        {amount} {symbol}
      </GridValue>

      <GridLabel>Network</GridLabel>
      <GridValue>{networkName}</GridValue>

      <GridDivider />

      <GridLabel>Created</GridLabel>
      <GridValue>{formatDate(created)}</GridValue>

      <GridDivider />


      <GridLabel>From</GridLabel>
      <GridValue>{shortenString(from)}</GridValue>

      {to && <>
        <GridLabel>To</GridLabel>
        <GridValue>{shortenString(to)}</GridValue> 
      </>}
      
      

      <GridLabel>Tx Hash</GridLabel>
      <GridValue>
        <Link target='_blank' href={txHashUrl}>
          {txHashTitle} 
        </Link>
      </GridValue> 
    </GridTable>
  </Container>
}

export default StatusContainer