import { FC } from 'react'
import { 
  Container,
  Title,
  Amount,
  Image,
  LinkStyled,
  AmountValue
} from './styled-components'
import {
  Tooltip
} from 'components/common'
import {
  GridTable,
  GridLabel,
  GridValue
} from 'components/pages/common'
import TProps from './types'
import {
  shortenString,
  defineExplorerURL,
  defineRealNetworkName
} from 'helpers'
import Icons from 'icons'

const defineComissionValue = (
  comissionToken: string | null,
  comissionFee: string | null
) => {
  if (comissionFee === '0' || comissionFee === '0.0') {
    return <>
      <GridLabel>Comission fee</GridLabel>
      <GridValue>Paid by Linkdrop</GridValue>
    </>
  }

  return <>
    <GridLabel>Comission fee <Tooltip position='right' content='This fee covers gas when recipient claims the link' /></GridLabel>
    <GridValue>{comissionFee} {comissionToken}</GridValue>
  </>
}

const TransactionContainer: FC<TProps> = ({
  title,
  amount,
  from,
  to,
  comissionFee,
  chainId,
  symbol,
  image,
  comissionToken
}) => {
  const networkName = defineRealNetworkName(chainId)
  const explorerUrl = defineExplorerURL(chainId)
  return <Container>
    <Title>{title}</Title>
    <Amount>
      <AmountValue>{amount}</AmountValue> {symbol}
      <Image src={image} />
    </Amount>
    <GridTable>
    <GridLabel>From</GridLabel>
      <GridValue>
        <LinkStyled target='_blank' href={`${explorerUrl}/address/${from}`}>
          {shortenString(from)}
          <Icons.ExternalLinkIcon />
        </LinkStyled>
      </GridValue>

      <GridLabel>Deposit to</GridLabel>
      <GridValue>
        <LinkStyled target='_blank' href={`${explorerUrl}/address/${from}`}>
          {shortenString(to)}
          <Icons.ExternalLinkIcon />
        </LinkStyled>
      </GridValue> 
        

      <GridLabel>Network</GridLabel>
      <GridValue>{networkName}</GridValue>

      {defineComissionValue(
        comissionToken,
        comissionFee
      )}
    </GridTable>
  </Container>
}

export default TransactionContainer