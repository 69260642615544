
import { Dispatch } from 'redux'
import * as actionsHistory from '../actions'
import { CreateLinkActions } from '../../create-link/types'
import { TokenActions } from '../../token/types'
import { HistoryActions } from '../types'
import { THistoryItem } from 'types'
import { RootState, IAppDispatch } from 'data/store'
import { alertError } from 'helpers'
import getERC20TokenData from '../../token/async-actions/get-erc20-token-data'
import getNativeTokenData from '../../token/async-actions/get-native-token-data'

export default function getHistory(
  page: number = 0,
  limit: number = 5
) {
  return async (
    dispatch: Dispatch<CreateLinkActions> &
              Dispatch<TokenActions> &
              Dispatch<HistoryActions> &
              IAppDispatch,
    getState: () => RootState
  ) => {
    dispatch(actionsHistory.setLoading(true))

    const {
      user: {
        chainId,
        sdk,
        address,
        provider
      },
      history: {
        items
      }
    } = getState()
    try {

      if (!sdk) {
        return alertError('SDK is not found')
      }

      const history = await sdk.getSenderHistory({
        sender: address,
        chainId: chainId as number,
        limit,
        offset: page * limit
      })
      const historyItems: THistoryItem[] = []
      const getHistoryPromises = []
      for (let x = 0; x < history.claimLinks.length; x++) {
        const link = history.claimLinks[x]
        if (link.tokenType === 'ERC20') {
          getHistoryPromises.push(getERC20TokenData(
            provider,
            link.token,
            link.chainId as number
          ))
        } else if (link.tokenType === 'NATIVE') {
          getHistoryPromises.push(getNativeTokenData(
            link.chainId as number
          ))
        }
        
      }
      const historyData = await Promise.all(getHistoryPromises)

      historyData.forEach((tokenItem, index) => {
        let historyItem = {
          ...history.claimLinks[index],
          decimals: tokenItem.decimals,
          symbol: tokenItem.symbol,
          image: tokenItem.image
        }

        historyItems.push(historyItem)
      })

      dispatch(actionsHistory.setItems([...items, ...historyItems]))
      dispatch(actionsHistory.setTotal(history.resultSet.total))
      
    } catch (err) {
      alertError('Some error occured. Please check console for more info')
      console.error(err)
    }
    dispatch(actionsHistory.setLoading(false))
  }
}
