import { FC, ReactElement, useEffect } from 'react'
import { Container } from './styled-components'
import ChooseAsset from './choose-asset'
import ConnectAwait from './connect-await'
import InitialScreen from './initial-screen'
import SetAmount from './set-amount'
import DepositScreen from './deposit-screen'
import TransactionScreen from './transaction-screen'
import LinkCreated from './link-created'
import HistoryScreen from './history-screen'
import ApproveScreen from './approve-screen'
import { RootState, IAppDispatch } from 'data/store'
import { TCreateLinkStep, TWalletName } from 'types'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Dispatch } from 'redux'
import chains from 'configs/chains'
import {
  WalletsListPage,
  PageHeader,
  WalletRedirectAwait,
  DownloadAwait,
  ZerionConnection
} from 'components/pages/common'
import * as createLinkActions from 'data/store/reducers/create-link/actions'
import { CreateLinkActions } from 'data/store/reducers/create-link/types'
import { UserActions } from 'data/store/reducers/user/types' 
import { useNetwork } from 'wagmi'
import { useHistory } from 'react-router-dom'
import {
  defineNetworkByStablecoinAddress,
  defineCreateLinkRedirectURL,
  defineInitialTokenAddress
} from 'helpers'
import { PageConnector } from '..'
import LinkStatusScreen from './link-status-screen'
import * as createLinkAsyncActions from 'data/store/reducers/create-link/async-actions'
import * as userAsyncActions from 'data/store/reducers/user/async-actions'
import { useQuery } from 'hooks'

const mapStateToProps = ({
  user: { address, walletApp, provider, chainId, initialized: userInitialized },
  createLink: {
    step,
    initialized,
    recommendedWalletApp,
    tokenDataInitialized
  },
  token: { address: tokenAddress }
}: RootState) => ({
  address,
  step,
  provider,
  recommendedWalletApp,
  chainId,
  userInitialized,
  tokenDataInitialized,
  tokenAddress,
  initialized,
  walletApp
})

const mapDispatcherToProps = (dispatch: Dispatch<CreateLinkActions> & IAppDispatch & Dispatch<UserActions>) => {
  return {
    setStep: (step: TCreateLinkStep) => dispatch(createLinkActions.setStep(step)),
    setRecommendedWalletApp: (walletApplication: TWalletName) => dispatch(createLinkActions.setRecommendedWalletApp(walletApplication)),
    getInitialData: (
      amount: string,
      tokenAddress: string,
      theme: string,
      tokensList: boolean,
      limits: boolean
    ) => dispatch(createLinkAsyncActions.getInitialData(
      amount,
      tokenAddress,
      theme,
      tokensList,
      limits
    )),
    getLimitsData: (
      tokenAddress: string,
      limits: boolean
    ) => dispatch(createLinkAsyncActions.getLimitsData(
      tokenAddress,
      limits
    )),
    getTokenData: (
      chainId: number,
      tokenAddress: string
    ) => dispatch(createLinkAsyncActions.getTokenData(
      chainId,
      tokenAddress
    )),
    switchNetwork: (chain: number, callback: () => void) => dispatch(userAsyncActions.switchNetwork(
      chain,
      callback
    )),
  }
}

type TDefineStep = (
  step: TCreateLinkStep,
  setStep: (step: TCreateLinkStep) => void,
  walletApp: TWalletName | null,
  setAddressCallback: (address: string) => void
) => ReactElement

const defineCurrentScreen: TDefineStep = (step, setStep, walletApp, setAddressCallback) => {
  switch (step) {
    case 'wallet_redirect_await':
      return <WalletRedirectAwait />
    case 'download_await':
      return <DownloadAwait />
    case 'initial':
      return <InitialScreen />
    case 'choose_asset':
      return <ChooseAsset />
    case 'download_await':
      return <ConnectAwait />
    case 'wallets_list':
      return <WalletsListPage
        setStep={setStep}
        recommendedWalletApp={walletApp}
      />
    case 'approve':
      return <ApproveScreen />
    case 'deposit':
      return <DepositScreen />
    case 'history':
      return <HistoryScreen />
    case 'link_created':
      return <LinkCreated />
    case 'set_amount':
      return <SetAmount />
    case 'transaction_screen':
      return <TransactionScreen />
    case 'link_status':
      return <LinkStatusScreen />
      case 'zerion_connection':
    return <ZerionConnection
      setStepCallback={() => setStep('initial')}
    />
    default:
      return <>loading</>
          
  }
}

const defineBackAction = (
  step: TCreateLinkStep,
  action: (prevoiusStep: TCreateLinkStep) => void
) => {
  switch (step) {
    case 'zerion_connection':
      return () => action('wallets_list')
    case 'link_status':
      return () => action('history')
    case 'history':
    case 'link_created':
    case 'link_status':
    case 'deposit':
    case 'approve':
    case 'choose_asset':
      return () => action('set_amount')
    default:
      return null
  }
}

const defineHeader = (
  step: TCreateLinkStep,
  action: (prevoiusStep: TCreateLinkStep) => void,
  chainId: number | null,
  switchNetwork: (chainId: number, callback: () => void) => void
) => {
  const backAction = defineBackAction(step, action)
  let title
  switch (step) {
    case 'wallets_list':
      title = 'Connect your wallet'
      break
    case 'history':
      title = 'History'
      break
    case 'link_status':
      title = 'Details'
      break
    case 'deposit':
    case 'approve':
      title = 'Preview send'
      break
    case 'choose_asset':
      title = 'Choose asset'
      break
    case 'link_created':
      title = 'Share your link'
      break
    case 'zerion_connection':
      title = 'Zerion connection'
      break
    case 'set_amount':
      title = 'Send tokens with a link'
      break
    default:
      return null
  }

  return <PageHeader
    chainId={chainId}
    backAction={backAction}
    title={title}
    switchNetwork={switchNetwork}
    switchNetworkCallback={() => {
      window.location.reload()
    }}

  />
}

const definePageType = (step: TCreateLinkStep) => {
  return 'secondary'
  switch (step) {
    case 'history':
    case 'choose_asset':
    case 'link_status':
      return 'secondary'
    default:
      return 'primary'
  }
}

// @ts-ignore
type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatcherToProps>

const CreateLinkPage: FC<ReduxType> = ({
  provider,
  step,
  setStep,
  userInitialized,
  chainId,
  address,
  switchNetwork,
  getInitialData,
  initialized,
  setRecommendedWalletApp,
  recommendedWalletApp,
  getTokenData,
  tokenDataInitialized,
  getLimitsData
}) => {

  const history = useHistory()
  const {
    chain_id: urlChainId,
    token_address: urlTokenAddress
  } = useParams<{
    chain_id: string,
    token_address: string
  }>()
  const query = useQuery()

  useEffect(() => {
    //@ts-ignore
    setRecommendedWalletApp((query.get('w') || 'metamask') as TWalletName)
  }, [])

  const { chain } = useNetwork()
  const connectorChainId = chain ? chain.id : null
  //@ts-ignore
  const providerConnectionUrl = provider ? provider.connection.url : null


  useEffect(() => {
    if (!urlTokenAddress || !urlChainId) { return }
    getTokenData(
      Number(urlChainId),
      urlTokenAddress
    )
  }, [urlChainId, urlTokenAddress])

  useEffect(() => {
    if (!Object.keys(chains).includes(String(connectorChainId))) { return }

    const chainId = urlChainId || connectorChainId
    if (!chainId) { return }

    const currentUrlTokenAddressNetwork = defineNetworkByStablecoinAddress(urlTokenAddress)
    // null - means it is not a stablecoin 
    let tokenAddress
    if (currentUrlTokenAddressNetwork === null) {
      tokenAddress = urlTokenAddress
    } else if (currentUrlTokenAddressNetwork === chainId) {
      tokenAddress = urlTokenAddress
    } else {
      tokenAddress = defineInitialTokenAddress(Number(chainId))
    }

    const redirectUrl = defineCreateLinkRedirectURL(
      query.get('amount') || '0',
      (query.get('w') as TWalletName) || 'metamask',
      query.get('theme') || 'light',
      chainId,
      tokenAddress,
      query.get('r'),
      query.get('tokens_list') || 'true',
      query.get('limits') || 'true'
    )
    history.push(redirectUrl)
  }, [ connectorChainId, userInitialized ])

  useEffect(() => {
    if (!Object.keys(chains).includes(String(connectorChainId))) { return }
    if (initialized) {
      return getLimitsData(
        urlTokenAddress,
        query.get('limits') === 'false' ? false : Boolean(query.get('limits'))
      )
    }
    if (tokenDataInitialized && userInitialized && chainId && address && providerConnectionUrl) {
      getInitialData(
        query.get('amount') || '0',
        urlTokenAddress,
        query.get('theme') || 'light',
        query.get('tokens_list') === 'false' ? false : Boolean(query.get('tokens_list')),
        query.get('limits') === 'false' ? false : Boolean(query.get('limits'))
      )
    }
  }, [
    urlTokenAddress,
    userInitialized,
    chainId,
    address,
    providerConnectionUrl,
    initialized,
    tokenDataInitialized
  ])
  
  return <PageConnector
    urlTokenAddress={urlTokenAddress}
    urlChainId={urlChainId}
    type={definePageType(step)}
  >
    <Container>
      {defineHeader(
        step,
        (step) => setStep(step),
        chainId,
        switchNetwork
      )}
      {defineCurrentScreen(
        step,
        setStep,
        recommendedWalletApp,
        () => {}
      )}
    </Container>
  </PageConnector>
}

export default connect(mapStateToProps, mapDispatcherToProps)(CreateLinkPage)