import { Dispatch } from 'redux'
import {
  CreateLinkActions,
} from '../types'
import {
  UserActions,
} from '../../user/types'
import {
  alertError,
  checkDepositTxHash,
  getDeposit
} from 'helpers'
import { RootState } from 'data/store'
import * as actionCreateLink from '../actions'

const checkDepositTransaction = (
  callback?: () => void
) => {
  return async (
    dispatch: Dispatch<CreateLinkActions> & Dispatch<UserActions>,
    getState: () => RootState
  ) => {
    dispatch(actionCreateLink.setLoading(true))
    const {
      user: {
        signer,
        claimLink,
        provider,
        address
      },
      createLink: {
        tokenAmount,
        escrowAddress
      },
      token: {
        address: tokenAddress
      }
    } = getState()

    try {
      if (claimLink) {
        // HERE TO CHECK DEPOSIT
        const finished = await checkDepositTxHash(
          claimLink,
          provider,
          tokenAddress as string,
          address,
          escrowAddress as string
        )
        callback && callback()
      }
    } catch (e) {
      const err = e as { code: string }
      alertError('Some error occured.Please check console for more info')
      console.error({ err })
    }
    dispatch(actionCreateLink.setLoading(false))
  }
}

export default checkDepositTransaction

