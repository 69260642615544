export enum Constants {
  LINK_SET_STEP = 'LINK_SET_STEP',
  LINK_SET_LOADING = 'LINK_SET_LOADING',
  LINK_SET_CHAIN_ID = 'LINK_SET_CHAIN_ID',
  LINK_SET_TITLE = 'LINK_SET_TITLE',
  LINK_SET_HASH = 'LINK_SET_HASH',
  LINK_SET_TRANSFER_ID= 'LINK_SET_TRANSFER_ID',
  LINK_SET_ESCROW_ADDRESS = 'LINK_SET_ESCROW_ADDRESS',
  LINK_SET_EXPIRATION_TIME = 'LINK_SET_EXPIRATION_TIME',
  LINK_SET_RECOMMENDED_WALLET = 'LINK_SET_RECOMMENDED_WALLET',
  LINK_SET_IS_MANUAL = 'LINK_SET_IS_MANUAL',
  LINK_SET_WEI_AMOUNT = 'LINK_SET_WEI_AMOUNT',
  LINK_SET_IS_CLAIMED = 'LINK_SET_IS_CLAIMED',
  LINK_SET_THEME = 'LINK_SET_THEME',
  LINK_SET_ERROR = 'LINK_SET_ERROR',
  LINK_SET_ADDRESS_MANUALLY_SET = 'LINK_SET_ADDRESS_MANUALLY_SET',
  LINK_SET_LINK_ID = 'LINK_SET_LINK_ID',
  LINK_SET_WALLET_APP = 'LINK_SET_WALLET_APP',
  LINK_SET_LINK_STATUS = 'LINK_SET_LINK_STATUS',
  LINK_SET_LAST_TX_HASH = 'LINK_SET_LAST_TX_HASH',
  LINK_SET_CREATED_AT = 'LINK_SET_CREATED_AT',
  LINK_SET_INITIALIZED = 'LINK_SET_INITIALIZED'
}