import { FC, useEffect } from 'react'
import {
  ScreenSubtitle,
  ScreenTitle,
  ButtonComponent,
  IconContainer
} from './styled-components'
import { RootState, IAppDispatch } from 'data/store'
import { connect } from 'react-redux'
import { defineExplorerURL } from 'helpers'
import * as linkAsyncActions from 'data/store/reducers/link/async-actions'
import { Loader } from 'components/common'
import { TClaimLinkStep } from 'types'
import * as linkActions from 'data/store/reducers/link/actions'
import { Dispatch } from 'redux'
import { LinkActions } from 'data/store/reducers/link/types'
import { Content } from '../styled-components'

const mapStateToProps = ({
  link: { hash, chainId }
}: RootState) => ({
  hash,
  chainId
})

const mapDispatcherToProps = (dispatch: IAppDispatch & Dispatch<LinkActions>) => {
  return {
    setStep: (step: TClaimLinkStep) => dispatch(linkActions.setStep(step)),
    checkTransactionStatus: (
      callback?: () => void
    ) => dispatch(
      linkAsyncActions.checkTransactionStatus(callback)
    )
  }
}

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatcherToProps>

const ClaimingProcess: FC<ReduxType> = ({
  hash,
  chainId,
  checkTransactionStatus,
  setStep
}) => {
  useEffect(() => {
    if (!hash) { return }
    checkTransactionStatus(() => setStep('claiming_finished'))
  }, [])

  const explorerUrl = chainId && hash ? <ButtonComponent
    href={`${defineExplorerURL(chainId)}/tx/${hash}`}
    title='View in explorer'
    target='_blank'
    appearance='default'
  /> : null
  return <Content>
    <IconContainer>
      <Loader />
    </IconContainer>
    <ScreenTitle>Processing Transaction</ScreenTitle>
    <ScreenSubtitle>This may take a few minutes. You can return to the app later to check on the status</ScreenSubtitle>
    {explorerUrl} 
  </Content>
}

export default connect(mapStateToProps, mapDispatcherToProps)(ClaimingProcess)
