import { action } from 'typesafe-actions';
import { Constants } from './constants';
import { TClaimLinkStep, TTokenType, TTheme, TLinkError, TLinkStatus, TWalletName } from 'types'

export function setStep(step: TClaimLinkStep) {
  return action(Constants.LINK_SET_STEP, { step })
}

export function setLoading(loading: boolean) {
  return action(Constants.LINK_SET_LOADING, { loading })
}

export function setLinkStatus (linkStatus: TLinkStatus) {
  return action(Constants.LINK_SET_LINK_STATUS, { linkStatus })
}

export function setInitialized (initialized: boolean) {
  return action(Constants.LINK_SET_INITIALIZED, { initialized })
}

export function setCreatedAt (createdAt: string) {
  return action(Constants.LINK_SET_CREATED_AT, { createdAt })
}

export function setChainId(chainId: number) {
  return action(Constants.LINK_SET_CHAIN_ID, { chainId })
}

export function setTitle (title: string) {
  return action(Constants.LINK_SET_TITLE, { title })
}

export function setHash (hash: string) {
  return action(Constants.LINK_SET_HASH, { hash })
}

export function setIsManual (isManual: boolean) {
  return action(Constants.LINK_SET_IS_MANUAL, { isManual })
}

export function setLastTxHash (lastTxHash: string) {
  return action(Constants.LINK_SET_LAST_TX_HASH, { lastTxHash })
}

export function setExpirationTime (expirationTime: string) {
  return action(Constants.LINK_SET_EXPIRATION_TIME, { expirationTime })
}

export function setRecommendedWalletApp (recommendedWalletApp: TWalletName | null) {
  return action(Constants.LINK_SET_RECOMMENDED_WALLET, { recommendedWalletApp })
}

export function setWeiAmount (weiAmount: string) {
  return action(Constants.LINK_SET_WEI_AMOUNT, { weiAmount })
}

export function setTransferId (transferId: string) {
  return action(Constants.LINK_SET_TRANSFER_ID, { transferId })
}

export function setEscrowAddress (escrowAddress: string) {
  return action(Constants.LINK_SET_ESCROW_ADDRESS, { escrowAddress })
}

export function setIsClaimed (isClaimed: boolean) {
  return action(Constants.LINK_SET_IS_CLAIMED, { isClaimed })
}

export function setAddressIsManuallySet (addressIsManuallySet: boolean) {
  return action(Constants.LINK_SET_ADDRESS_MANUALLY_SET, { addressIsManuallySet })
}

export function setTheme (theme: TTheme) {
  return action(
    Constants.LINK_SET_THEME,
    {
      theme
    }
  )
}

export function setError (error: TLinkError | null) {
  return action(
    Constants.LINK_SET_ERROR,
    {
      error
    }
  )
}

export function setLinkId (linkId: string) {
  return action(
    Constants.LINK_SET_LINK_ID,
    {
      linkId
    }
  )
}
