import styled from 'styled-components'

export const Container = styled.div`
  max-width: 572px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;

  header {
    display: none;
  }

  @media (max-width: 767px) {
    header {
      display: flex;
    }
  }
`

export const Content = styled.div`
  text-align: center;
  width: 100%;
`
