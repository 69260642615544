import { FC, useEffect } from 'react'
import {  AdditionalAction } from './styled-components'
import { Image, Title, Subtitle, ButtonStyled } from 'components/pages/common/styles/styled-components'
import GasPrice from 'images/gas-price.png'
import { RootState, IAppDispatch } from 'data/store'
import { connect } from 'react-redux'
import { LinkActions } from 'data/store/reducers/link/types'
import { TokenActions } from 'data/store/reducers/token/types'
import * as linkAsyncActions from 'data/store/reducers/link/async-actions'
import { Dispatch } from 'redux'
import * as linkActions from 'data/store/reducers/link/actions'
import { TClaimLinkStep } from 'types'

const mapStateToProps = ({
  link: { addressIsManuallySet },
  token: {
    tokenType
  },
  user: { signer }
}: RootState) => ({
  tokenType,
  addressIsManuallySet,
  signer
})

const mapDispatcherToProps = (dispatch: Dispatch<LinkActions> & Dispatch<TokenActions> & IAppDispatch) => {
  return {
    claimERC1155: () => dispatch(
      linkAsyncActions.claimERC1155()
    ),
    claimERC721: () => dispatch(
      linkAsyncActions.claimERC721()
    ),
    claimERC20: () => dispatch(
      linkAsyncActions.claimERC20()
    ),
    setStep: (step: TClaimLinkStep) => dispatch(linkActions.setStep(step))
  }
}

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatcherToProps>

const HighGasPrice: FC<ReduxType> = ({
  addressIsManuallySet,
  tokenType,
  claimERC1155,
  claimERC721,
  claimERC20,
  signer,
  setStep
}) => {

  // useEffect(() => {
  //   plausibleApi.invokeEvent({
  //     eventName: 'gas_price_high',
  //     data: {
  //       campaignId: campaignId as string,
  //     }
  //   })
  // }, [])

  return <>
    <Image src={GasPrice} />
    <Title>Gas price is high</Title>
    <Subtitle>Network is busy. Carry gas cost by yourself or try a bit later</Subtitle>
    <ButtonStyled
      appearance='default'
      onClick={() => { setStep('initial') }}
    >
      Claim later
    </ButtonStyled>
    {!addressIsManuallySet && signer && <AdditionalAction
      onClick={() => {
        // plausibleApi.invokeEvent({
        //   eventName: 'user_covered_gas',
        //   data: {
        //     campaignId: campaignId as string,
        //   }
        // })
        if (tokenType === 'ERC1155') {
          return claimERC1155()
        }
        if (tokenType === 'ERC721') {
          return claimERC721()
        }
        if (tokenType === 'ERC20' || tokenType === 'NATIVE') {
          return claimERC20()
        }
      }}
    >
      Nevermind, I pay for gas
    </AdditionalAction>}
  </>
}

export default connect(mapStateToProps, mapDispatcherToProps)(HighGasPrice)