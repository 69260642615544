import { FC, useState } from 'react'
import {
  TitleComponent,
  ScreenButton,
  Container,
  TextComponent,
  WalletIcon
} from './styled-components'
import { RootState, IAppDispatch } from 'data/store'
import { connect } from 'react-redux'
import WalletsImg from 'images/wallets.png'
import { AdditionalNoteComponent } from 'linkdrop-ui'
import * as linkActions from 'data/store/reducers/link/actions'
import { Dispatch } from 'redux';
import { LinkActions } from 'data/store/reducers/link/types'
import {
  defineSystem
} from 'helpers'
import { OverlayScreen } from 'linkdrop-ui'
import { PopupWhatIsWalletContents } from 'components/pages/common'
import { plausibleApi } from 'data/api'
import { useTheme } from 'styled-components'

const mapStateToProps = ({
  token: { name, image, address },
  token: { tokenType },
  user: {
    chainId
  }
}: RootState) => ({
  name,
  image,
  tokenType,
  address,
  chainId
})

const mapDispatcherToProps = (dispatch: IAppDispatch & Dispatch<LinkActions>) => {
  return {
    chooseWallet: () => dispatch(
      linkActions.setStep('wallets_list')
    )
  }
}

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatcherToProps>

const ChooseWallet: FC<ReduxType> = ({
  chooseWallet,
  tokenType,
  address,
  chainId
}) => {
  const system = defineSystem()
  const [ showPopup, setShowPopup ] = useState<boolean>(false)
  const theme = useTheme()

  return <Container> 
    <WalletIcon src={WalletsImg} />
    <TitleComponent>Connect your wallet</TitleComponent>
    <TextComponent>
      To claim {tokenType === 'ERC20' ? 'tokens' : 'an NFT'} you will need to have a non-custodial crypto-wallet set up and ready to use
    </TextComponent>
    <ScreenButton
      appearance='action'
      onClick={async () => {
        plausibleApi.invokeEvent({
          eventName: 'goto_choose_wallet',
          data: {
            tokenAddress: String(address),
            chainId: String(chainId)
          }
        })
        chooseWallet()
      }
    }>
      Connect
    </ScreenButton>
    {system !== 'desktop' && <AdditionalNoteComponent
      text='What is a Wallet?'
      position='bottom'
      onClick={() => {
        plausibleApi.invokeEvent({
          eventName: 'educate_me',
          data: {
            screen: 'what_is_a_wallet',
            tokenAddress: String(address),
            chainId: String(chainId)
          }
        })
        setShowPopup(true)
      }}
    />}
    {showPopup && <OverlayScreen
      title='What is a Wallet?'
      // @ts-ignore
      headerLogo={theme.logo as string}
      onCloseAction={() => { setShowPopup(false) }}
      mainAction={() => { setShowPopup(false) }}
    >
      <PopupWhatIsWalletContents />
    </OverlayScreen>}
  </Container>
}

export default connect(mapStateToProps, mapDispatcherToProps)(ChooseWallet)