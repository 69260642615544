
import { Dispatch } from 'redux'
import * as actionsCreateLink from '../actions'
import * as actionsUser from '../../user/actions'
import * as asyncActionsUser from '../../user/async-actions'
import { CreateLinkActions } from '../types'
import { TokenActions } from '../../token/types'
import { UserActions } from '../../user/types'
import getTokenBalance from '../../token/async-actions/get-token-balance'
import { RootState } from 'data/store'
import { alertError } from 'helpers'
import {
  TERC20Token,
  TTokenType
} from 'types'
import { maxLimitAmount } from 'configs/application'
import { BigNumber, ethers } from 'ethers'
import { datadogLogs } from '@datadog/browser-logs'

export default function getInitialData(
  tokenAmount: string,
  tokenAddress: string,
  theme: string,
  tokensList: boolean,
  limits: boolean
) {
  return async (
    dispatch: Dispatch<CreateLinkActions> & Dispatch<TokenActions> & Dispatch<UserActions>,
    getState: () => RootState
  ) => {
    dispatch(actionsCreateLink.setLoading(true))
    try {


      const {
        user: {
          provider,
          address,
          chainId,
          sdk
        },
        createLink: {
          step
        },
        token: {
          tokenType
        }
      } = getState()

      if (!sdk) {
        return alertError('SDK is not provided')
      }

      if (
        tokenAddress
      ) {
        await getTokenBalance(
          dispatch,
          address,
          tokenAddress,
          provider
        )
        // await asyncActionsUser.getPrices(dispatch)
      }

      if (
        tokenAddress &&
        chainId &&
        address
      ) {

        if (tokensList) {
          const tokens = await asyncActionsUser.getUserTokens(
            dispatch,
            getState
          )
  
          if (tokens) {
            dispatch(actionsUser.setERC20Tokens(tokens as TERC20Token[]))
          }
        }

      }

      if (!limits) {
        dispatch(actionsCreateLink.setMaxTransferLimit(maxLimitAmount))
        dispatch(actionsCreateLink.setMinTransferLimit(0))
      } else {
        const limitsData = await sdk.getLimits({
          token: tokenAddress as string,
          tokenType: tokenType as TTokenType,
          chainId: Number(chainId)
        })
      
        if (limitsData) {
          dispatch(actionsCreateLink.setMaxTransferLimit(limitsData.maxTransferAmount))
          dispatch(actionsCreateLink.setMinTransferLimit(limitsData.minTransferAmount))
        }
      }

      dispatch(actionsCreateLink.setInitialized(true))

      if (step !== 'link_status') {
        if (tokenAmount && !isNaN(Number(tokenAmount))) {
          const {
            token: {
              decimals
            },
          } = getState()
          const amountAtomic = String(ethers.utils.formatUnits(tokenAmount, decimals))
          dispatch(actionsCreateLink.setAmount(BigNumber.from(tokenAmount)))
          dispatch(actionsCreateLink.setHumanReadableAmount(amountAtomic))
        }
        dispatch(actionsCreateLink.setStep('set_amount')) 
      }

    } catch (
      error: any
    ) {
      alertError(error.error)
      // datadogLogs.logger.error('Error occurred', {}, new Error(error.error))
    }

    dispatch(actionsCreateLink.setLoading(false))
  }
}
