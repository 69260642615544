import { FC } from 'react'
import {
  Header,
  HeaderLogoContainer,
  HeaderLogo,
  NetworkSwitcherStyled,
  Account,
  Address,
  Logout,
  HeaderLogoLink,
  Balance
} from './styled-components'
import {
  defineNativeTokenSymbol,
  shortenString,
  truncate
}  from 'helpers'
import { useQuery } from 'hooks'
import { ethers } from 'ethers'
import { TProps } from './types'
import Icons from 'icons'
import { useTheme } from 'styled-components'

const DesktopHeader: FC<TProps> = ({
  switchNetwork,
  switchNetworkCallback,
  chainId,
  balance,
  address,
  logout
}) => {
  const tokenSymbol = chainId && defineNativeTokenSymbol({ chainId })
  const theme = useTheme()
  const query = useQuery()
  const currentTheme = query.get('theme') === 'light' ? 'light' : 'dark'

  return <Header>
    <HeaderLogoContainer>
      <HeaderLogoLink onClick={() => window.location.reload()}>

        {/* @ts-ignore */}
        <HeaderLogo src={theme.logo as string} />
      </HeaderLogoLink>
    </HeaderLogoContainer>
    <NetworkSwitcherStyled
      enableChainName
      currentTheme={currentTheme}
      chainId={chainId}
      switchNetwork={switchNetwork}
      switchNetworkCallback={switchNetworkCallback}
    />
    {address && <Account>
      <Balance>
      {truncate(ethers.utils.formatUnits(String(balance) || '0', 18), 7, '')} 
      </Balance>{tokenSymbol}
      <Address>
        {shortenString(address)}
      </Address>
      
    </Account>}
    {address && <Logout
      currentTheme={currentTheme}
      onClick={() => {
        logout()
      }}
    >
      <Icons.LogoutIcon />
    </Logout>}
  </Header>
}

export default DesktopHeader