import styled from 'styled-components'
import { Button, Text, Title } from 'components/common'

export const TitleComponent = styled(Title)`
  font-size: 22px;
  color: ${props => props.theme.primaryTextColor};
  margin: 0 0 12px;
  font-weight: 600;
`

export const Container = styled.div`
  text-align: center;
  width: 100%;
`

export const ScreenButton = styled(Button)`
  max-width: 100%;
  width: 100%;
  margin-bottom: 20px;

  svg {
    margin-right: 10px;
  }
`

export const TextComponent = styled(Text)`
  margin-bottom: 32px;

  span {
    font-weight: 700;
  }
`

export const AdditionalAction = styled.div`
  color: ${props => props.theme.additionalTextColor};
  font-size: 16px;
  text-align: center;
  cursor: pointer;
`


export const WalletIcon = styled.img`
  max-width: 24px;
`

export const LinkButton = styled.div`
  cursor: pointer;
  color: ${props => props.theme.linkColor};
  font-size: 16px;
  text-align: center;
  margin-bottom: 36px;
`