import React, {
  FC,
  useState,
  useMemo,
  useEffect
} from 'react'
import {
  HeaderNetworkWrapper,
  HeaderNetwork,
  HeaderChainName,
  MiniPopupCustomItem,
  MiniPopupDivider,
  LogoutIcon
} from './styled-components'
import {
  defineNetworkIcon,
  defineRealNetworkName,
  defineInitialTokenAddress,
  defineCreateLinkRedirectURL
} from 'helpers'
import chains from 'configs/chains'
import { MiniPopup } from 'components/common'
import { TProps } from './types'
import { Dispatch } from 'redux'
import { TWalletName } from 'types'
import Icons from 'icons'
import * as userAsyncActions from 'data/store/reducers/user/async-actions'
import { IAppDispatch } from 'data/store'
import { UserActions } from 'data/store/reducers/user/types'
import { connect } from 'react-redux'
import { useQuery } from 'hooks'
import { useHistory } from 'react-router-dom'

const mapDispatcherToProps = (dispatch:Dispatch<UserActions> & IAppDispatch ) => {
  return {
    // @ts-ignore
    logout: () => dispatch(userAsyncActions.logout()),
  }
}

// @ts-ignore
type ReduxType = ReturnType<typeof mapDispatcherToProps>

const NetworksSwitcher: FC<TProps & ReduxType> = ({
  chainId,
  switchNetwork,
  switchNetworkCallback,
  logout,
  className,
  enableChainName
}) => {

  const [ showToggleChain, setShowToggleChain ] = useState(false)
  const chainsAvailable: string[] = Object.keys(chains)

  const [ hideTestnets, setHideTestnets ] = useState<boolean>(true)
  const query = useQuery()
  const currentTheme = query.get('theme') === 'light' ? 'light' : 'dark'

  const history = useHistory()

  useEffect(() => {
    const keydownCallback = (evt: KeyboardEvent) => {
      if (evt.key === 'w') {
        setHideTestnets(false)
      }
    }

    const keyupCallback = (evt: KeyboardEvent) => {
      if (evt.key === 'w') {
        setHideTestnets(true)
      }
    }
    document.addEventListener('keydown', keydownCallback)
    document.addEventListener('keyup', keyupCallback)

    return () => {
      document.removeEventListener('keydown', keydownCallback)
      document.removeEventListener('keyup', keyupCallback)
    }

  }, [])

  const chainsOrdered = useMemo(() => {
    return Object.entries(chains).sort((a, b) => {
      const displayNameA = a[1].displayName
      const displayNameB = b[1].displayName
      if (displayNameB > displayNameA) return -1
      if (displayNameA > displayNameB) return 1
      return 0
    })
  }, [chains])

  if (!chainId) {
    return null
  }

  const chainName = chainId && defineRealNetworkName(chainId)

  // @ts-ignore
  const chainsPopup = switchNetwork && switchNetworkCallback && showToggleChain && <MiniPopup onClose={() => { setShowToggleChain(false) }}>
    {chainsOrdered.map(([chain, data]) => {
      if (chainsAvailable.length > 0 && !chainsAvailable.find(network => Number(chain) === Number(network))) {
        return null
      }

      if (hideTestnets) {
        if (data.testnet) {
          return null
        }
      }
      const currentChain = data
      return <MiniPopupCustomItem
        currentTheme={currentTheme}
        onClick={() => {
          switchNetwork(Number(chain), () => {
            const tokenAddress =  defineInitialTokenAddress(Number(chain))
            const redirectUrl = defineCreateLinkRedirectURL(
              query.get('amount') || '0',
              (query.get('w') as TWalletName) || 'metamask',
              query.get('theme') || 'light',
              Number(chain),
              tokenAddress,
              query.get('r'),
              query.get('tokens_list') || 'true',
              query.get('limits') || 'true'
            )

            history.push(redirectUrl)
            switchNetworkCallback()
          })
        }}
        active={Number(chainId) === Number(chain)}
      > 
        <HeaderNetwork src={defineNetworkIcon(Number(chain))}/>
        {currentChain.displayName}
      </MiniPopupCustomItem>
    })}
    {
      !enableChainName && <>
        <MiniPopupDivider />

        <MiniPopupCustomItem
          onClick={() => {
            logout()
          }}
          active={false}
        >
          <LogoutIcon>
            <Icons.LogoutIcon />
          </LogoutIcon>
          Logout
        </MiniPopupCustomItem>
      </>
    }
    
  </MiniPopup>

  return <HeaderNetworkWrapper
    className={className}
    currentTheme={currentTheme}
    enableChainName={enableChainName}
    onClick={() => {
      setShowToggleChain(!showToggleChain)
    }}
  >
    {chainsPopup}
    <HeaderNetwork alt="network logo" src={defineNetworkIcon(chainId)} />
    {enableChainName && <HeaderChainName>{chainName}</HeaderChainName>}
  </HeaderNetworkWrapper>
}

// @ts-ignore
export default connect(null, mapDispatcherToProps)(NetworksSwitcher)