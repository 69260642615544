import { FC } from 'react'
import {
  TokenAmount,
  TitleERC20Component,
  SymbolERC20,
  Container,
  GradientWrapperStyled,
  NetworkName,
  TokenAmountValue
} from './styled-components'
import { utils } from 'ethers'
import TProps, { TStatus } from './types'
import { defineRealNetworkName } from 'helpers'


const ERC20TokenPreview: FC<TProps> = ({
  image,
  amount,
  decimals,
  name,
  status,
  chainId
}) => {
  const networkName = defineRealNetworkName(chainId)
  return <Container> 
    <GradientWrapperStyled>
      <TitleERC20Component>
        <TokenAmount>
          <TokenAmountValue>{utils.formatUnits(amount as string, decimals)}</TokenAmountValue>
          <SymbolERC20>{name}</SymbolERC20>
        </TokenAmount>
        
        <NetworkName>on {networkName}</NetworkName>
      </TitleERC20Component>
    </GradientWrapperStyled>
  </Container>
}

export default ERC20TokenPreview