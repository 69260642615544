import { FC } from 'react'
import { 
  Grid
} from './styled-components'

const GridTable: FC = ({
  children
}) => {

  return <Grid>
    {children}
  </Grid>
}

export default GridTable