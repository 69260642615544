import { UserState, UserActions } from './types';
import { Constants } from './constants';
import {  BigNumber } from 'ethers'

const initialState: UserState = {
  address: '',
  loading: false,
  provider: null,
  userProvider: null,
  chainId: null,
  sdk: null,
  initialized: false,
  hasConnector: false,
  signer: null,
  ethPrice: '0',
  maticPrice: '0',
  balance: BigNumber.from(0),
  claimLink: null,
  erc20Tokens: [],
  walletApp: null,
  connectorId: null
}

export function userReducer(
  state: UserState = initialState,
  action: UserActions
): UserState {
  switch (action.type) {
    case Constants.USER_SET_BALANCE:
      return { ...state, balance: action.payload.balance }
    case Constants.USER_SET_CONNECTOR_ID:
      return { ...state, connectorId: action.payload.connectorId }
    case Constants.USER_SET_ERC20_TOKENS:
      return { ...state, erc20Tokens: action.payload.erc20Tokens }
    case Constants.USER_SET_ADDRESS:
      return { ...state, address: action.payload.address }
    case Constants.USER_SET_CLAIM_LINK:
      return {...state, claimLink: action.payload.claimLink }
    case Constants.USER_SET_LOADING:
      return {...state, loading: action.payload.loading }
    case Constants.USER_SET_ETH_PRICE:
      return {...state, ethPrice: action.payload.ethPrice }
    case Constants.USER_SET_MATIC_PRICE:
      return {...state, maticPrice: action.payload.maticPrice }
    case Constants.USER_SET_PROVIDER:
      return {...state, provider: action.payload.provider }
    case Constants.USER_SET_USER_PROVIDER:
      return {...state, userProvider: action.payload.userProvider }
    case Constants.USER_SET_CHAIN_ID:
      return {...state, chainId: action.payload.chainId }
    case Constants.USER_SET_SDK:
      return {...state, sdk: action.payload.sdk }
    case Constants.USER_SET_INITIALIZED:
      return {...state, initialized: action.payload.initialized }
    case Constants.USER_SET_HAS_CONNECTOR:
      return {...state, hasConnector: action.payload.hasConnector }
    case Constants.USER_SET_WALLET_APP:
      return {...state, walletApp: action.payload.walletApp }
    case Constants.USER_SET_SIGNER:
      return {...state, signer: action.payload.signer }
    case Constants.USER_SET_EMAIL:
      return {...state, email: action.payload.email }
    default:
      return state;
  }
}