
import { Dispatch } from 'redux';
import { LinkActions } from '../types'
import { ethers, BigNumber } from 'ethers'
import { RootState } from 'data/store'
import contracts from 'configs/contracts'
import { signReceiverAddress } from '@linkdrop/contracts/scripts/utils.js'
import * as dropActions from '../actions'
import * as userActions from '../../user/actions'
import { UserActions } from '../../user/types'
import { resolveENS, defineJSONRpcUrl, handleClaimResponseError, alertError } from 'helpers'
import { AxiosError } from 'axios'
import gasPriceLimits from 'configs/gas-price-limits'
import { plausibleApi } from 'data/api'
const { REACT_APP_INFURA_ID = '' } = process.env

export default function claimERC20(
  manualAddress?: string,
  checkGasPrice?: boolean
) {
  return async (
    dispatch: Dispatch<LinkActions> & Dispatch<UserActions>,
    getState: () => RootState
  ) => {
    dispatch(dropActions.setLoading(true))
    let {
      user: {
        sdk,
        address,
        provider,
        signer,
        email,
        claimLink
      },
      token: {
        address: tokenAddress,
        amount
      },
      link: {
        isManual,
        weiAmount,
        expirationTime,
        chainId
      }
    } = getState()

    if (!chainId) {
      dispatch(dropActions.setLoading(false))
      return alert(`chainId is not provided`)
    }
    if (!tokenAddress) {
      dispatch(dropActions.setLoading(false))
      return alert(`tokenAddress is not provided`)
    }

    if (!amount) {
      dispatch(dropActions.setLoading(false))
      return alert(`amount is not provided`)
    }

    if (!expirationTime) {
      dispatch(dropActions.setLoading(false))
      return alert(`expirationTime is not provided`)
    }

    try {
      const txHash = await claimLink?.redeem(address)
      plausibleApi.invokeEvent({
        eventName: 'claim_link_redeem_started',
        data: {
          tokenAddress: String(tokenAddress),
          chainId: String(chainId)
        }
      })
      if (txHash) {
        dispatch(dropActions.setHash(txHash))
        dispatch(dropActions.setStep('claiming_process'))
      }
      
    } catch (error: any) {
      // handleClaimResponseError(dispatch, error)
      alertError(error.message)
    }
    dispatch(dropActions.setLoading(false))
  }
}
