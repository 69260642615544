
import { Dispatch } from 'redux'
import * as actionsLink from '../actions'
import { LinkActions } from '../types'
import { UserActions } from '../../user/types'
import {
  alertError,
  checkRedeemTxHash
} from 'helpers'
import { TokenActions } from '../../token/types'
import { RootState } from 'data/store'
import { plausibleApi } from 'data/api'

export default function checkTransactionStatus(
  callback?: () => void
) {
  return async (
    dispatch: Dispatch<LinkActions> & Dispatch<TokenActions> & Dispatch<UserActions>,
    getState: () => RootState
  ) => {
    dispatch(actionsLink.setLoading(true))

    const {
      user: {
        claimLink,
        chainId
      },
      token: {
        address: tokenAddress
      }
    } = getState()

    try {
      if (claimLink) {
        const finished = await checkRedeemTxHash(claimLink)
        plausibleApi.invokeEvent({
          eventName: 'claim_link_redeem_finished',
          data: {
            tokenAddress: String(tokenAddress),
            chainId: String(chainId)
          }
        })
        callback && callback()
      }
    } catch (e) {
      const err = e as { code: string }
      alertError('Some error occured.Please check console for more info')
      console.error({ err })
    }
    dispatch(actionsLink.setLoading(false))

  }
}
