import { FC } from 'react'
import {
  Container,
  Subtitle,
  ButtonContainer,
  ButtonStyled
} from '../styled-components'
import { RootState, IAppDispatch } from 'data/store'
import * as createLinkActions from 'data/store/reducers/create-link/actions'
import { CreateLinkActions } from 'data/store/reducers/create-link/types'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { TCreateLinkStep } from 'types'
import * as createLinkAsyncActions from 'data/store/reducers/create-link/async-actions'
import { TransactionContainer } from 'components/pages/common'

const mapStateToProps = ({

  user: {
    address,
    provider,
    chainId,
    initialized: userInitialized
  },
  createLink: {
    step,
    tokenAmount,
    loading,
    humanReadableAmount,
    feeTokenSymbol,
    escrowAddress,
    feeAmount,
    feeToken,
    humanReadableFeeAmount
  },
  token: {
    name,
    image,
    address: tokenAddress
  }
}: RootState) => ({
  address,
  step,
  provider,
  chainId,
  userInitialized,
  tokenAddress,
  name,
  tokenAmount,
  feeTokenSymbol,
  loading,
  humanReadableAmount,
  escrowAddress,
  humanReadableFeeAmount,
  image,
  feeAmount,
  feeToken
})

const mapDispatcherToProps = (dispatch: Dispatch<CreateLinkActions> & IAppDispatch) => {
  return {
    setStep: (step: TCreateLinkStep) => dispatch(createLinkActions.setStep(step)),
    approve: (callback: () => void) => dispatch(createLinkAsyncActions.approve(callback))
  }
}

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatcherToProps>

const ApproveScreen: FC<ReduxType> = ({
  address,
  chainId,
  name,
  feeTokenSymbol,
  approve,
  image,
  setStep,
  loading,
  humanReadableFeeAmount,
  humanReadableAmount,
  escrowAddress
}) => {
  return <Container>
    <Subtitle>
      We'll create a shareable web link that your recipient can open to claim their crypto. If not claimed in 2 weeks, we'll return funds.
    </Subtitle>

    <TransactionContainer
      from={address}
      chainId={chainId as number}
      title='Amount to send (estimate)'
      to={escrowAddress || ''}
      symbol={name}
      comissionFee={humanReadableFeeAmount}
      image={image as string}
      comissionToken={feeTokenSymbol}
      amount={humanReadableAmount}
    />

    <ButtonContainer>
      <ButtonStyled
        onClick={() => setStep('set_amount')}
      >
        Cancel
      </ButtonStyled>
      <ButtonStyled
        appearance='action'
        loading={loading}
        disabled={
          loading
        }
        onClick={() => approve(() => setStep('deposit'))}
      >
        Approve
      </ButtonStyled>
    </ButtonContainer>
    
  </Container>
}

export default connect(mapStateToProps, mapDispatcherToProps)(ApproveScreen)