import { FC, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { ERC20Input } from 'components/pages/common'
import { PoweredByFooterStyled } from './styled-components'
import {
  Container,
  Subtitle,
  ButtonContainer,
  ButtonStyled
} from '../styled-components'
import { RootState, IAppDispatch } from 'data/store'
import { TCreateLinkStep } from 'types'
import { Dispatch } from 'redux'
import { ethers } from 'ethers'
import * as createLinkActions from 'data/store/reducers/create-link/actions'
import { CreateLinkActions } from 'data/store/reducers/create-link/types'
import * as createLinkAsyncActions from 'data/store/reducers/create-link/async-actions'
import { useQuery } from 'hooks'

const mapStateToProps = ({
  createLink: {
    step,
    initialized,
    loading,
    tokenAmount,
    maxTransferLimit,
    minTransferLimit,
    humanReadableAmount
  },
  user: {
    address,
    chainId,
    erc20Tokens
  },
  token: {
    balance,
    decimals,
    name,
    tokenType,
    address: tokenAddress,
    image
  }
}: RootState) => ({
  step,
  address,
  initialized,
  humanReadableAmount,
  tokenAddress,
  chainId,
  tokenType,
  loading,
  balance,
  decimals,
  image,
  name,
  tokenAmount,
  maxTransferLimit,
  minTransferLimit,
  erc20Tokens
})

const mapDispatcherToProps = (dispatch: Dispatch<CreateLinkActions> & IAppDispatch) => {
  return {
    setStep: (step: TCreateLinkStep) => dispatch(createLinkActions.setStep(step)),
    setAmount: (
      tokenAmount: string,
      approveCallback: () => void,
      depositCallback: () => void
    ) => dispatch(createLinkAsyncActions.setTokenAmount(
      tokenAmount,
      approveCallback,
      depositCallback
    ))
  }
}

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatcherToProps>

const SetAmount: FC<ReduxType> = ({
  minTransferLimit,
  maxTransferLimit,
  balance,
  name,
  loading,
  setAmount,
  setStep,
  decimals,
  erc20Tokens,
  chainId,
  tokenAddress,
  image,
  address,
  tokenAmount,
  humanReadableAmount
}) => {
  const userLimit = ethers.utils.formatUnits(String(balance), decimals)
  const [ value, setValue ] = useState<string>('')
  const linkFundsMaxLimit = maxTransferLimit.toString()
  const linkFundsMinLimit = minTransferLimit.toString()

  const queryParams = useQuery()

  const userLimitFailed = ethers.utils.parseUnits((value || '0'), decimals).gt(ethers.utils.parseUnits(userLimit, decimals))
  const applicationLimitMaxFailed = ethers.utils.parseUnits((value || '0'), decimals).gt(linkFundsMaxLimit)
  const applicationLimitMinFailed = ethers.utils.parseUnits((value || '0'), decimals).lt(linkFundsMinLimit)
  return <Container>
    <Subtitle>
      Create a claim link you can share to any phone number or email. If it's not claimed in 2 weeks, we'll return funds
    </Subtitle>
    <ERC20Input
      value={value}
      symbol={name}
      setValue={setValue}
      toggleAsset={() => setStep('choose_asset')}
      balance={userLimit}
      balanceFailed={userLimitFailed}
      applicationLimitMaxFailed={applicationLimitMaxFailed}
      applicationLimitMinFailed={applicationLimitMinFailed}
      userLimitFailed={userLimitFailed}
      loading={loading}
      minTransferLimit={ethers.utils.formatUnits(linkFundsMinLimit, decimals)}
      maxTransferLimit={ethers.utils.formatUnits(linkFundsMaxLimit, decimals)}
      chainId={chainId as number}
      image={image as string}
      decimals={decimals as number}
      hideTokenList={queryParams.get('tokens_list') === 'false' ? true : false}
    />
    <ButtonContainer vertical>
      <ButtonStyled
        appearance='action'
        loading={loading}
        disabled={
          loading ||
          userLimitFailed ||
          Number(value) === 0 ||
          value === '' ||
          applicationLimitMinFailed || 
          applicationLimitMaxFailed
        }
        onClick={() => {
          setAmount(
            value,
            () => setStep('approve'),
            () => setStep('deposit')
          )
        }}
      >
        Confirm
      </ButtonStyled>

      <ButtonStyled
        onClick={() => {
          setStep('history')
        }}
      >
        History
      </ButtonStyled>
    </ButtonContainer>
    <PoweredByFooterStyled />
  </Container>
}

export default connect(mapStateToProps, mapDispatcherToProps)(SetAmount)