import styled, { css } from 'styled-components'
import {
  InputFieldProps,
} from './types'

export const InputField = styled.input<InputFieldProps>`
  color: ${(props) => props.theme.primaryTextColor};
  transition: color .3s;

  caret-color: ${props => props.theme.primaryHighlightColor};
  outline: none;
  margin: 0;
  background: none;
  font-family: Inter, Arial, Helvetica;
  height: 100%;
  border: none;
  width: 100%;
  padding: 0px;
  font-size: 16px;
  line-height: 72px;
  font-size: 64px;
  font-weight: 400;

  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: 'ss01' on;
  letter-spacing: -0.02em;

  ::placeholder {
    color: ${(props) => props.theme.additionalTextColor};
  }

  &:focus,
  &:focus-visible {
    color: ${(props) => props.theme.primaryTextColor};
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  [type=number] {
    -moz-appearance: textfield;
  }

  ${(props) =>
      props.disabled &&
      css`
        cursor: not-allowed;
        color: ${(props) => props.theme.inputDisabledTextColor};
      `}

  ${(props) =>
    props.error &&
    css`

    `}
`
