import { FC } from 'react'
import { WidgetStyled, WrapperContainer } from './styled-components'
import TProps from './types'

const GradientWrapper: FC<TProps> = ({
  children,
  className
}) => {
  return <WrapperContainer className={className}>
    <WidgetStyled>
      {children}
    </WidgetStyled>
  </WrapperContainer>
}

export default GradientWrapper