import { FC } from 'react'
import { RootState } from 'data/store'
import { connect } from 'react-redux'
import { 
  TitleComponent,
  ButtonsContainer,
  TokenImageLarge,
  TokenImageContainer,
  DoneIcon,
  Subtitle,
  DoneIconERC20
} from './styled-components'
import {
  ERC20TokenPreview,
  ClaimingFinishedButton,
  PoweredByFooter,
  ClaimingFinishedSecondaryButton
} from 'components/pages/common'
import { Content } from '../styled-components'
import AlreadyClaimedERC20 from 'images/already-claimed-erc20.png'

const mapStateToProps = ({
  link: {
    chainId
  },
  token: {
    image,
    name,
    decimals,
    tokenType,
    amount
  }
}: RootState) => ({
  image,
  name,
  tokenType,
  decimals,
  amount,
  chainId
})

type ReduxType = ReturnType<typeof mapStateToProps>

const AlreadyClaimed: FC<ReduxType> = ({
  image,
  name,
  tokenType,
  amount,
  decimals,
  chainId
}) => {

  const content = tokenType === 'ERC20' || tokenType === 'NATIVE' ? <ERC20TokenPreview
    name={name}
    image={image as string}
    amount={String(amount)}
    decimals={decimals}
    chainId={chainId}
  /> : <>
    {image && <TokenImageContainer>
      <DoneIcon />
      <TokenImageLarge
        src={image}
        alt={name}
      />
    </TokenImageContainer>}
  </>

  return <Content>
    {content}
    <TitleComponent>
      {tokenType === 'ERC20' || tokenType === 'NATIVE' && <DoneIconERC20 src={AlreadyClaimedERC20} />}
      Already claimed
    </TitleComponent>
    <Subtitle>
      Somebody has already claimed this link. In case it was you, find {tokenType === 'ERC20' || tokenType === 'NATIVE' ? 'tokens' : 'NFT'} in your wallet
    </Subtitle>
    <ButtonsContainer>
      <ClaimingFinishedButton />
      <ClaimingFinishedSecondaryButton />
    </ButtonsContainer>
    <PoweredByFooter />
  </Content>
}

export default connect(mapStateToProps)(AlreadyClaimed)