import { TTokenERC20Data } from 'types'
import ETH from 'images/eth.png'
import PolygonMATIC from 'images/polygon-matic.png'
import XDAI from 'images/xdai.png'
import DegenNetworkIcon from 'images/degen-network.png'

type TGetNativeTokenData = (
  chainId: number | null
) => TTokenERC20Data

const getNativeTokenData: TGetNativeTokenData = (chainId) => {
  if (
    chainId === 100
  ) {
    return { symbol: 'xDAI', decimals: 18, description: '', image: XDAI }
  }
  if (
    chainId === 8453 ||
    chainId === 84531 ||
    chainId === 42161 ||
    chainId === 10 ||
    chainId === 11155111
  ) {
    return { symbol: 'ETH', decimals: 18, description: '', image: ETH }
  } else if (chainId === 666666666) {
    return { symbol: 'DEGEN', decimals: 18, description: '', image: DegenNetworkIcon }
  } else if (chainId === 43114) {
    return { symbol: 'AVAX', decimals: 18, description: '', image: ETH }
  } else {
    return { symbol: 'MATIC', decimals: 18, description: '', image: PolygonMATIC }
  }
}


export default getNativeTokenData