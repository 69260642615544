import styled from "styled-components"
import {
  GridLabel
} from 'components/pages/common'

export const Container = styled.div`
  position: relative;
  padding: 24px 16px 16px;
  border-radius: 16px;
  width: 100%;
  border: 1px solid ${props => props.theme.primaryBorderColor};
`

export const GridLabelUppercase = styled(GridLabel)`
  text-transform: capitalize;
`