import styled from 'styled-components'
import { PoweredByFooter } from 'components/pages/common'
import ButtonComponent from 'components/common/button'
import Title from 'components/common/title'

export const Container = styled.div`
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const Subtitle = styled.h2`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.primaryTextColor};
  margin: 0 0 12px;
  span {
    font-weight: 700;
  }
`

export const IntroImage = styled.div`
  margin-bottom: 32px;
`

export const PoweredByFooterStyled = styled(PoweredByFooter)`
  margin-bottom: 10px;
`

export const Content = styled.div`
  margin-bottom: 50px;
`

export const ButtonStyled = styled(ButtonComponent)`
  width: 100%;
`

export const TitleStyled = styled(Title)`
  margin-bottom: 20px;
  span {
    font-weight: 700;
    line-height: 1;
    display: flex-inline;
    align-items: center;
  }
`

export const LoaderContainer = styled.div`
  position: absolute;
  inset: 0;
  z-index: 1;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => props.theme.secondaryBackgroundColor};
  opacity: 0.98;
`

export const TokenImage = styled.img`
  width: 24px;
  height: 24px;
  object-fit: cover;
  vertical-align: middle;
  object-position: center;
`