import styled, { css } from 'styled-components'
import { Widget } from 'components/common'

export const WidgetStyled = styled(Widget)`
  width: 100%;
  position: relative;
  height: 100%;
  border: none;
`

export const WrapperContainer = styled.div`
  padding: 2px;
  width: 100%;
  background-image: linear-gradient(45deg, #07AEFF, #EF08FF);
  border-radius: 16px;
  margin: 0px 0 24px;
  min-height: 194px;
  max-width: 343px;
`