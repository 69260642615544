import { action } from 'typesafe-actions'
import { Constants } from './constants'
import { TCreateLinkStep, TWalletName } from 'types'
import { BigNumber, BigNumberish } from 'ethers'

export function setStep( step: TCreateLinkStep) {
  return action(Constants.CREATE_LINK_SET_STEP, { step })
}

export function setTokenDataInitialized( tokenDataInitialized: boolean) {
  return action(Constants.CREATE_LINK_SET_TOKEN_DATA_INITIALIZED, { tokenDataInitialized })
}

export function setLoading (loading: boolean) {
  return action(Constants.CREATE_LINK_SET_LOADING, { loading })
}

export function setRecommendedWalletApp (recommendedWalletApp: TWalletName | null) {
  return action(Constants.CREATE_LINK_SET_RECOMMENDED_WALLET, { recommendedWalletApp })
}

export function setInitialized (initialized: boolean) {
  return action(Constants.CREATE_LINK_SET_INITIALIZED, { initialized })
}

export function setIsDepositWithAuthAvailable (isDepositWithAuthAvailable: boolean) {
  return action(Constants.CREATE_LINK_SET_IS_DEPOSIT_WITH_AUTH_AVAILABLE, { isDepositWithAuthAvailable })
}

export function setFeeToken (feeToken: string | null) {
  return action(Constants.CREATE_LINK_SET_FEE_TOKEN, { feeToken })
}

export function setFeeAmount (feeAmount: string) {
  return action(Constants.CREATE_LINK_SET_FEE_AMOUNT, { feeAmount })
}

export function setFeeTokenSymbol (feeTokenSymbol: string) {
  return action(Constants.CREATE_LINK_SET_FEE_TOKEN_SYMBOL, { feeTokenSymbol })
}

export function setHeumanReadableFeeAmount (humanReadableFeeAmount: string) {
  return action(Constants.CREATE_LINK_SET_HUMAN_READABLE_FEE_AMOUNT, { humanReadableFeeAmount })
}

export function setAmount (tokenAmount: BigNumber) {
  return action(Constants.CREATE_LINK_SET_AMOUNT, { tokenAmount })
}

export function setHumanReadableAmount (humanReadableAmount: string) {
  return action(Constants.CREATE_LINK_SET_HUMAN_READABLE_AMOUNT, { humanReadableAmount })
}

export function setEscrowAddress (escrowAddress: string) {
  return action(Constants.CREATE_LINK_SET_ESCROW_ADDRESS, { escrowAddress })
}

export function setTransferId (transferId: string) {
  return action(Constants.CREATE_LINK_SET_TRANSFER_ID, { transferId })
}

export function setApproveTxHash (approveTxHash: string) {
  return action(Constants.CREATE_LINK_SET_APPROVE_TX_HASH, { approveTxHash })
}

export function setDepositTxHash (depositTxHash: string) {
  return action(Constants.CREATE_LINK_SET_DEPOSIT_TX_HASH, { depositTxHash })
}

export function setMaxTransferLimit (value: BigNumberish) {
  return action(Constants.CREATE_LINK_SET_MAX_TRANSFER_LIMIT, { value })
}

export function setMinTransferLimit (value: BigNumberish) {
  return action(Constants.CREATE_LINK_SET_MIN_TRANSFER_LIMIT, { value })
}

