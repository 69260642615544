
import defineTokenAddressBySymbol from './define-token-address-by-symbol'

const defineInitialTokenAddress = (
  chainId: number | null
) => {
  if (chainId === 666666666) {
    return defineTokenAddressBySymbol('degen', chainId)
  }

  if (chainId === 100) {
    return defineTokenAddressBySymbol('eure', chainId)
  }

  const tokenAddress = defineTokenAddressBySymbol('usdc', chainId)
  return tokenAddress
}

export default defineInitialTokenAddress