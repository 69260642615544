export enum Constants {
  TOKEN_SET_NAME = 'TOKEN_SET_NAME',
  TOKEN_SET_IMAGE = 'TOKEN_SET_IMAGE',
  TOKEN_SET_DESCRIPTION = 'TOKEN_SET_DESCRIPTION',
  TOKEN_SET_DECIMALS = 'TOKEN_SET_DESCIMALS',
  TOKEN_SET_SYMBOL = 'TOKEN_SET_SYMBOL',
  TOKEN_SET_ADDRESS = 'TOKEN_SET_ADDRESS',
  TOKEN_SET_AMOUNT = 'TOKEN_SET_AMOUNT',
  TOKEN_SET_TOKEN_ID = 'TOKEN_SET_TOKEN_ID',
  TOKEN_SET_BALANCE = 'TOKEN_SET_BALANCE',
  TOKEN_SET_TOKEN_TYPE = 'TOKEN_SET_TOKEN_TYPE'
}