import { TWalletName } from 'types'
import { сreateQueryString } from '.'

type TDefineCreateLinkRedirectURL = (
  amount: string,
  wallet: TWalletName,
  theme: string,
  chainId: string | number,
  tokenAddress: string,
  redirectUrl: string | null,
  tokensList?: string,
  limits?: string
) => string

const defineCreateLinkRedirectURL: TDefineCreateLinkRedirectURL = (
  amount,
  wallet,
  theme,
  chainId,
  tokenAddress,
  redirectUrl,
  tokensList,
  limits
) => {
  const params = сreateQueryString({
    amount,
    w: wallet,
    theme: theme,
    tokens_list: tokensList ? String(tokensList) : undefined,
    limits: limits ? String(limits) : undefined,
    r: redirectUrl ? encodeURIComponent(redirectUrl) : undefined
  })
  const url = `/create/${chainId}/${tokenAddress}?${params}`
  return url
}

export default defineCreateLinkRedirectURL