import React, { FC } from 'react'
import { TextComponent } from './styled-components'

import TProps from './types'

const Text: FC<TProps> = ({
  children,
  className,
  dangerouslySetInnerHTML,
  onClick
}) => {
  return <TextComponent
    onClick={onClick}
    dangerouslySetInnerHTML={dangerouslySetInnerHTML}
    className={className}
  >
    {children}
  </TextComponent>
}

export default Text