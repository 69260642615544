import { FC, useState, useEffect } from 'react'
import { TCreateLinkStep } from 'types'
import { RootState } from 'data/store'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { CreateLinkActions } from 'data/store/reducers/create-link/types'
import * as createLinkActions from 'data/store/reducers/create-link/actions'
import { SetConnectorScreen } from 'components/pages/common'
import { PageHeader } from 'components/pages/common'
import { Container } from './styled-components'

const mapStateToProps = ({
  createLink: { loading, tokenDataInitialized },
  user: { loading: userLoading, address, initialized },
  token: { name, image }
}: RootState) => ({
  loading,
  userLoading,
  address,
  initialized,
  name,
  tokenDataInitialized,
  image
})

const mapDispatcherToProps = (dispatch: Dispatch<CreateLinkActions>) => {
  return {
    setStep: (step: TCreateLinkStep) => dispatch(createLinkActions.setStep(step))
  }
}

type ReduxType = ReturnType<typeof mapDispatcherToProps> & ReturnType<typeof mapStateToProps>

const InitialScreen: FC<ReduxType> = ({
  setStep,
  loading,
  userLoading,
  name,
  tokenDataInitialized,
  image
}) => {
  const [ start, setStart ] = useState<boolean>(false)
  useEffect(() => {
    setStart(true)
  }, [])
  return <Container>
    <PageHeader />
    <SetConnectorScreen
      tokenSymbol={name}
      tokenImage={image}
      loading={
        loading ||
        userLoading ||
        !start
      }

      tokenDataInitialized={tokenDataInitialized}
      onClick={() => {
        setStep('wallets_list')
      }}
    />
  </Container>
}

export default connect(mapStateToProps, mapDispatcherToProps)(InitialScreen)