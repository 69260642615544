import React, { FC } from 'react'
import { TitleComponent } from './styled-components'

import TProps from './types'

const Title: FC<TProps> = ({
  children,
  className,
  dangerouslySetInnerHTML
}) => {
  return <TitleComponent
    className={className}
    dangerouslySetInnerHTML={dangerouslySetInnerHTML}
  >
    {children}
  </TitleComponent>
}

export default Title