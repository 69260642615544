import { FC} from 'react'
import {
  Page,
  MainContent,
  Content
} from './styled-components'
import { ThemeProvider } from 'styled-components'
import themes from 'themes'
import { RootState, IAppDispatch } from 'data/store'
import { connect } from 'react-redux'
import { useConnection, useQuery } from 'hooks'
import { TProps } from './types'
import * as userAsyncActions from 'data/store/reducers/user/async-actions'
import {
  DesktopHeader
} from 'components/pages/common'

const mapStateToProps = ({
  user: {
    address,
    provider,
    chainId,
    initialized,
    balance
  },
  createLink: { step }
}: RootState) => ({
  address,
  balance,
  step,
  provider,
  chainId,
  initialized
})

const mapDispatcherToProps = (dispatch: IAppDispatch) => {
  return {
    initialize: (
      provider: any,
      urlChainId?: string,
      address?: string | null
    ) => dispatch(userAsyncActions.initialize(
      provider,
      urlChainId,
      address
    )),
    reinitialize: (
      address: string,
      urlTokenAddress?: string,
      provider?: any,
    ) => dispatch(userAsyncActions.updateUserData(
      address,
      urlTokenAddress,
      provider
    )),
    switchNetwork: (chain: number, callback: () => void) => dispatch(userAsyncActions.switchNetwork(
      chain,
      callback
    )),
    logout: () => dispatch(userAsyncActions.logout()),
  }
}

// @ts-ignore
type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatcherToProps>

const PageComponent: FC<ReduxType & TProps> = ({
  children,
  initialize,
  reinitialize,
  initialized,
  chainId,
  urlChainId,
  address,
  urlTokenAddress,
  switchNetwork,
  type = 'primary',
  balance,
  logout
}) => {
  const queryParams = useQuery()
  const config = queryParams.get('theme') || 'light'
  const currentTheme = themes(config)
  useConnection(
    initialized,
    initialize,
    reinitialize,
    urlChainId,
    urlTokenAddress
  )

  return <ThemeProvider theme={currentTheme}>
    <Page type={type}>
      <DesktopHeader
        address={address}
        logout={logout}
        chainId={chainId}
        balance={String(balance)}
        switchNetwork={switchNetwork}
        switchNetworkCallback={() => {
          window.location.reload()
        }}

      />
      <MainContent>
        <Content>
          {children}
        </Content>
      </MainContent>
    </Page>
  </ThemeProvider>
}


export default connect(mapStateToProps, mapDispatcherToProps)(PageComponent)
