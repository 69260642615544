import { Dispatch } from 'redux'
import {
  CreateLinkActions,
} from '../types'
import {
  UserActions,
} from '../../user/types'
import {
  alertError
} from 'helpers'
import { RootState } from 'data/store'
import * as actionCreateLink from '../actions'
import randomBytes from 'randombytes'
import { ethers } from 'ethers'
import { Escrow } from 'abi'
import { plausibleApi } from 'data/api'
import { nativeTokenAddress } from 'configs/application'

const deposit = (
  callback?: () => void
) => {
  return async (
    dispatch: Dispatch<CreateLinkActions> & Dispatch<UserActions>,
    getState: () => RootState
  ) => {
    dispatch(actionCreateLink.setLoading(true))
    const {
      user: {
        signer,
        claimLink,
        provider,
        address,
        connectorId,
        chainId
      },
      createLink: {
        tokenAmount,
        escrowAddress,
        feeAmount,
        feeToken,
        feeTokenSymbol
      },
      token: {
        address: tokenAddress
      },
      user: {
        balance
      }

    } = getState()

    if(feeToken === nativeTokenAddress && ethers.BigNumber.from(feeAmount).gt(balance)) {
      dispatch(actionCreateLink.setLoading(false))
      plausibleApi.invokeEvent({
        eventName: 'no_enough_tokens_for_deposit',
        data: {
          tokenAddress: String(tokenAddress),
          chainId: String(chainId)
        }
      })
      return alert(`You don’t have enough ${feeTokenSymbol} to proceed transaction`)
    }


    try {
      if (claimLink) {
        const getRandomBytes = (length: number) => new Uint8Array(randomBytes(length)) 
        
        const sendTransaction = async ({ to, value, data } : {
          to: string, value: string, data: string
        }) => {

          if (connectorId === "walletConnect") {
            signer.sendTransaction({ to, value, data })

            const getTxHash = () => new Promise((resolve, reject) => {
              const filter = {
                address: escrowAddress as string,
                topics: [ethers.utils.id('Deposit(address,address,address,uint120,uint8,uint256,uint128,address,uint128)')]
              }
              const contract = new ethers.Contract(escrowAddress as string, Escrow.abi, provider)
              contract.on(filter, (sender, token, transferId, expiration, tokenType, tokenId, amount, feeToken, fee, event) => {
                resolve(event.transactionHash as string)
              })
            })

            const txHash = await getTxHash()
            
            return {
              hash: String(txHash)
            }
          }
          const tx = await signer.sendTransaction({ to, value, data })
          return { hash: tx.hash }
        }

        plausibleApi.invokeEvent({
          eventName: 'deposit_started',
          data: {
            tokenAddress: String(tokenAddress),
            chainId: String(chainId)
          }
        })

        const result = await claimLink.deposit({
          sendTransaction,
          getRandomBytes
        })

        plausibleApi.invokeEvent({
          eventName: 'deposit_finished',
          data: {
            tokenAddress: String(tokenAddress),
            chainId: String(chainId)
          }
        })

        dispatch(actionCreateLink.setLoading(false))
        if (result) {
          if (result.txHash) {
            dispatch(actionCreateLink.setDepositTxHash(result.txHash))
            if (callback) {
              callback()
            }
          }
        }
      }
      
    } catch (e) {
      const err = e as { code: string }
      if (err.code === "ACTION_REJECTED") {
        plausibleApi.invokeEvent({
          eventName: 'deposit_rejected',
          data: {
            tokenAddress: String(tokenAddress),
            chainId: String(chainId)
          }
        })
        alertError('You cancelled the link creation. Please try again')
      } else {
        alertError('Some error occured, please check console for more information')
      }
      console.error({ err })

      dispatch(actionCreateLink.setLoading(false))
    }
  }
}

export default deposit

