import styled, { css } from "styled-components"
import { TProps } from "./types"

export const TooltipContainer = styled.div`
  position: relative;
  display: inline-block;
  vertical-align: sub;
  &:hover div {
    display: block;
  }
`

export const TooltipWindow = styled.div<TProps>`
  position: absolute;
  font-size: 16px;
  filter: drop-shadow(0px 2px 8px rgba(40, 41, 61, 0.04)) drop-shadow(0px 16px 24px rgba(96, 97, 112, 0.16));
  width: 304px;
  padding: 16px;
  border: 1px solid ${props => props.theme.primaryBorderColor};
  background: ${props => props.theme.primaryBackgroundColor};
  border-radius: 16px;
  display: none;

  @media (max-width: 767px) {
    width: 200px;
  }


  ${props => props.position === 'right' && css`
    top: 50%;
    transform: translateY(-50%);
    left: calc(100% + 10px);

    &:before {
      width: 10px;
      content: '';
      display: inline-block;
      height: 10px;
      position: absolute;
      left: -6px;
      top: 50%;
      border: 1px solid ${props => props.theme.primaryBorderColor};
      border-right: none;
      border-top: none;
      background: ${props => props.theme.primaryBackgroundColor};
      transform: translateY(-50%) rotate(45deg);
    }
  `}
`
