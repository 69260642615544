import { FC, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { IAppDispatch, RootState } from 'data/store'
import * as linkAsyncActions from 'data/store/reducers/link/async-actions'
import { connect } from 'react-redux'
import {
  Container,
  IconContainer,
  LoadingText,
  LoadingTitle
} from './styled-components'
import Icons from 'icons'
import { Dispatch } from 'redux'
import { LinkActions } from 'data/store/reducers/link/types'
import * as linkActions from 'data/store/reducers/link/actions'
import { TClaimLinkStep } from 'types'

const mapStateToProps = ({
  link: { error }
}: RootState) => ({ error })

const mapDispatcherToProps = (dispatch: Dispatch<LinkActions> & IAppDispatch) => {
  return {
    // getLink: (
    //   code: string,
    //   callback: (claimCode: string) => void
    // ) => dispatch(
    //   linkAsyncActions.getLinkFromURL(
    //     code,
    //     callback
    //   )
    // ),
    setStep: (step: TClaimLinkStep) => dispatch(linkActions.setStep(step))
  }
}

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatcherToProps>

const ShortLinkPage: FC<ReduxType> = ({
  // getLink,
  setStep
}) => {

  useEffect(() => {
    // getLink(window.location.href, (linkCode) => setStep('loading'))    
  }, [])
  
  return <Container>
    <IconContainer>
      <Icons.LinkdropIcon />
    </IconContainer>
    <LoadingTitle>Linkdrop</LoadingTitle>
  </Container>
}

export default connect(mapStateToProps, mapDispatcherToProps)(ShortLinkPage)