import styled from "styled-components"
import { Button, Link } from 'components/common'

export const Container = styled.div`
  position: relative;
  padding: 24px 16px 16px;
  border-radius: 16px;
  width: 100%;
  border: 1px solid ${props => props.theme.primaryBorderColor};
`

export const Title = styled.h2`
  margin: 0 0 12px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  color: ${props => props.theme.primaryTextColor};
`

export const Amount = styled.p`
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  display: flex;
  align-items: center;
  margin: 0 0 32px;
  color: ${props => props.theme.primaryTextColor};
`

export const AmountValue = styled.span`
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: 'ss01' on;
  letter-spacing: -0.02em;
`

export const Image = styled.img`
  width: 44px;
  object-fit: cover;
  border-radius: 44px;
  object-position: center;
  margin-left: 12px;
  height: 44px;
`

export const LinkStyled = styled(Link)`
  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: flex-end;
`