import { Dispatch } from 'redux'
import {
  CreateLinkActions,
} from '../types'
import {
  UserActions,
} from '../../user/types'
import {
  alertError,
} from 'helpers'
import { RootState } from 'data/store'
import * as actionCreateLink from '../actions'
import randomBytes from 'randombytes'
import { TCustomSigner } from 'types'
import { plausibleApi } from 'data/api'

const depositWithAuth = (
  callback?: () => void
) => {
  return async (
    dispatch: Dispatch<CreateLinkActions> & Dispatch<UserActions>,
    getState: () => RootState
  ) => {
    dispatch(actionCreateLink.setLoading(true))
    const {
      user: {
        signer,
        claimLink,
        chainId
      },
      token: {
        address: tokenAddress
      }
    } = getState()

    try {
      if (claimLink) {
        const getRandomBytes = (length: number) => new Uint8Array(randomBytes(length)) 

        const signTypedData = (
          domain: any,
          types: any,
          message: Record<string, string | number>
        ) => (signer as TCustomSigner)._signTypedData(domain, types, message)
        
        plausibleApi.invokeEvent({
          eventName: 'deposit_with_auth_started',
          data: {
            tokenAddress: String(tokenAddress),
            chainId: String(chainId)
          }
        })
        
        const result = await claimLink.depositWithAuthorization({
          getRandomBytes,
          signTypedData
        })

        plausibleApi.invokeEvent({
          eventName: 'deposit_with_auth_finished',
          data: {
            tokenAddress: String(tokenAddress),
            chainId: String(chainId)
          }
        })

        dispatch(actionCreateLink.setLoading(false))

        if (result) {
          if (result.txHash) {
            dispatch(actionCreateLink.setDepositTxHash(result.txHash))

            if (callback) {
              callback()
            }
          }
        }
      }
      
    } catch (e) {
      const err = e as { code: string }
      if (err.code === "ACTION_REJECTED") {
        plausibleApi.invokeEvent({
          eventName: 'deposit_with_auth_rejected',
          data: {
            tokenAddress: String(tokenAddress),
            chainId: String(chainId)
          }
        })
        alertError('You cancelled the link creation. Please try again')
      } else {

        alertError('Some error occured, please check console for more information')
      }
      console.error({ err })
      dispatch(actionCreateLink.setLoading(false))
    }
  }
}

export default depositWithAuth

