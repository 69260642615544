import styled from 'styled-components'
import {
  Button,
  Text,
  TokenImage,
  Title
} from 'components/common'

import { PoweredByFooter } from 'components/pages/common'

type TScreenButton = {
  onClick: () => void
}

export const TitleComponent = styled(Title)`
  margin: 0 0 12px;
  font-weight: 600;
  font-size: 22px;
`

export const ScreenButton = styled(Button)<TScreenButton>`
  max-width: 100%;
  width: 100%;
  margin-bottom: 16px;
`

export const TextComponent = styled(Text)`
  text-align: center;
  margin-bottom: 32px;
  font-size: 16px;
`

export const TokenImageContainer = styled(TokenImage)`
  margin: 0 auto 25px;
  background: ${props => props.theme.widgetColor};
`

export const UserAddress = styled.span`
  color: ${props => props.theme.linkColor};
`


export const PoweredByFooterStyled = styled(PoweredByFooter)`
  margin-bottom: 12px;
`