import { ClaimLink, ClaimLinkV2 } from 'linkdrop-p2p-sdk'
import { getDeposit } from 'helpers'
import { ethers } from 'ethers'

type TCheckDepositTxHash = (
  claimLink: ClaimLink | ClaimLinkV2,
  provider: any,
  tokenAddress: string,
  address: string,
  escrowAddress: string
) => Promise<boolean | {amount: number}>

const checkDepositTxHash: TCheckDepositTxHash = (
  claimLink,
  provider,
  tokenAddress,
  address,
  escrowAddress
) => {
  return new Promise((resolve, reject) => {
    (async () => {
      const interval = window.setInterval(async () => {
        const deposit = await getDeposit(
          escrowAddress as string,
          provider,
          tokenAddress as string,
          address,
          claimLink.transferId
        )

        const depositAmount = ethers.BigNumber.from(deposit.amount)

        if (depositAmount.gt('0')) {
          resolve(true)
          clearInterval(interval)
        }

        // if (claimLink) {
        //   const statusData = await claimLink.getStatus()
        //   if (statusData) {
        //     const { status } = statusData
        //     console.log({ status })
        //     if (status === 'deposited') {
        //       resolve(true)
        //       clearInterval(interval)
        //     }
        //   }
        // }
      }, 1000)
    
    })()
  })
}

export default checkDepositTxHash