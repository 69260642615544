import {
  FC,
  useEffect,
  useState
} from 'react'
import {
  Container,
  Subtitle,
  ButtonContainer,
  ButtonStyled
} from '../styled-components'
import { connect } from 'react-redux'
import {
  copyToClipboard,
  defineSystem,
  alertError,
  defineNetworkName,
  defineRedeemLinkURL,
  defineRealNetworkName
} from 'helpers'
import {
  GradientWrapperStyled,
  Amount,
  CopyContainerStyled,
  PoweredByFooterStyled,
} from './styled-components'
import { useQuery } from 'hooks'
import { plausibleApi } from 'data/api'
import { TSystem } from 'types'
import { CreateLinkActions } from 'data/store/reducers/create-link/types'
import * as createLinkActions from 'data/store/reducers/create-link/actions'
import { TCreateLinkStep } from 'types'
import { RootState, IAppDispatch } from 'data/store'
import { Dispatch } from 'redux'

const mapStateToProps = ({
  createLink: {
    humanReadableAmount,
    recommendedWalletApp
  },
  token: {
    name,
    address: tokenAddress
  },
  user: {
    claimLink,
    chainId
  }
}: RootState) => ({
  claimLink,
  humanReadableAmount,
  name,
  tokenAddress,
  recommendedWalletApp,
  chainId
})

const mapDispatcherToProps = (dispatch: Dispatch<CreateLinkActions> & IAppDispatch) => {
  return {
    setStep: (step: TCreateLinkStep) => dispatch(createLinkActions.setStep(step))
  }
}

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatcherToProps>


const defineShareButton = (
  system: TSystem,
  claimUrl: string,
  copied: boolean,
  setCopied: (copied: boolean) => void,
  networkName: string
) => {
  if (system !== 'desktop') {
    return <ButtonStyled
      appearance='action'
      onClick={async () => {
        try {
          const shareData = {
            title: "Linkdrop Link",
            url: claimUrl,
            text: `I’ve sent you tokens on ${networkName}. You can claim it on any wallet`
          }
          await navigator.share(shareData)
        } catch (err) {
          alertError('Your link was not shared. Please try again')
          console.error(err)
        }
      }}
    >
      Share
    </ButtonStyled>
  }
  return <ButtonStyled
    appearance='action'
    onClick={() => {
      copyToClipboard({
        value: claimUrl
      })
      setCopied(true)
      setTimeout(() => {
        setCopied(false)
      }, 2000)
    }}
  >
    {copied ? 'Link Copied!' : 'Copy Link'}
  </ButtonStyled>
}

const LinkCreated: FC<ReduxType> = ({
  humanReadableAmount,
  name,
  claimLink,
  chainId,
  recommendedWalletApp,
  setStep
}) => {
  const system = defineSystem()
  const queryParams = useQuery()
  const [ copied, setCopied ] = useState<boolean>(false)

  const networkName = defineRealNetworkName(chainId)
  useEffect(() => {
    plausibleApi.invokeEvent({
      eventName: 'claim_link_generated',
        data: {
          network: defineNetworkName(chainId)
        }
    })
  }, [])
  
  const claimUrl = defineRedeemLinkURL(
    (claimLink || {}).claimUrl as string,
    recommendedWalletApp,
    queryParams.get('theme') || 'light',
    queryParams.get('r')
  )

  return <Container>
    <Subtitle>
      Your link is ready. Share this link to any phone number or email. If it's not claimed in 2 weeks, we'll return funds.
    </Subtitle>
    <GradientWrapperStyled>
      <Amount>
        {parseFloat(humanReadableAmount)} {name}
      </Amount>
      <PoweredByFooterStyled />
    </GradientWrapperStyled>
    {claimLink && <CopyContainerStyled text={claimUrl}/>}
    <ButtonContainer vertical>
      {defineShareButton(
        system,
        claimUrl,
        copied,
        setCopied,
        networkName
      )}
      <ButtonStyled
        onClick={() => {
          setStep('set_amount')
        }}
      >
        Create another link
      </ButtonStyled>
    </ButtonContainer>
  </Container>
}

export default connect(mapStateToProps, mapDispatcherToProps)(LinkCreated)