import { Dispatch } from 'redux'
import * as actions from '../actions'
import { UserActions } from '../types'
import { CreateLinkActions } from '../../create-link/types'
import { LinkdropP2P } from 'linkdrop-p2p-sdk'
import randomBytes from 'randombytes'
import { defineJSONRpcUrl } from 'helpers'
import { ethers } from 'ethers'
import switchNetwork from './switch-network'
import { providers } from 'ethers'

import { IAppDispatch, RootState } from 'data/store'


const { REACT_APP_INFURA_ID, REACT_APP_ZUPLO_API_KEY } = process.env
const initialize = (
  connector: any,
  chainIdToBeConnected?: string,
  userAddress?: string | null
) => {

  // @ts-ignore
  return async (
    dispatch: Dispatch<UserActions> & Dispatch<CreateLinkActions> & IAppDispatch,
    getState: () => RootState
  ) => {
    const { user: { sdk } } = getState()
    if (userAddress && connector) {
      dispatch(actions.setLoading(true))

      
      const provider = new providers.Web3Provider(await connector.getProvider())
      const network = await provider.getNetwork()

      dispatch(actions.setHasConnector(true))
      dispatch(actions.setAddress(userAddress))
      if (connector.id) {
        dispatch(actions.setConnectorId(connector.id))
      }
 
      const signer = provider.getSigner()
      const jsonRpcUrl = defineJSONRpcUrl({
        chainId: Number(network.chainId),
        infuraPk: REACT_APP_INFURA_ID as string
      })
      const jsonRpcProvider = new ethers.providers.JsonRpcProvider(jsonRpcUrl)
      if (chainIdToBeConnected && (network.chainId !== Number(chainIdToBeConnected))) {
        dispatch(actions.setUserProvider(provider))
        dispatch(switchNetwork(Number(chainIdToBeConnected), () => window.location.reload()))
      } else {
    
        dispatch(actions.setChainId(network.chainId))
        dispatch(actions.setProvider(jsonRpcProvider))
        dispatch(actions.setUserProvider(provider))
        dispatch(actions.setSigner(signer))
        const tokenAmount = await provider.getBalance(userAddress)
        dispatch(actions.setBalance(tokenAmount))

        if (!sdk) {
          const getRandomBytes = (length: number) => new Uint8Array(randomBytes(length)) 
          const sdk = new LinkdropP2P({
            apiKey: REACT_APP_ZUPLO_API_KEY as string,
            baseUrl: window.location.origin,
            getRandomBytes
          })
          dispatch(actions.setSDK(sdk)) 
        }
        
        dispatch(actions.setInitialized(true))
        dispatch(actions.setLoading(false))

      }
    }


  }
}


export default initialize