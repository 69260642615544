import { FC } from 'react'
import { Image } from './styled-components'
import ExpiredError from 'images/expired-error.png'
import { RootState } from 'data/store'
import { connect } from 'react-redux'
import {Title, Subtitle } from 'components/pages/common/styles/styled-components'

const mapStateToProps = ({
  token: {
    tokenType
  },
}: RootState) => ({
  tokenType
})

type ReduxType = ReturnType<typeof mapStateToProps>

const ErrorComponent: FC<ReduxType> = ({
  tokenType
}) => {
  return <>
    <Image src={ExpiredError} />
    <Title>{tokenType === 'ERC20' || tokenType === 'NATIVE' ? 'Tokens' : 'NFT'} already claimed</Title>
    <Subtitle>Please check your wallet</Subtitle>
  </>
}

export default connect(mapStateToProps)(ErrorComponent)