import { FC } from 'react'
import {
  Container,
  Subtitle,
  ButtonContainer,
  ButtonStyled
} from '../styled-components'
import { TransactionContainer } from 'components/pages/common'
import { RootState, IAppDispatch } from 'data/store'
import * as createLinkActions from 'data/store/reducers/create-link/actions'
import { CreateLinkActions } from 'data/store/reducers/create-link/types'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { defineNetworkName } from 'helpers'
import { TCreateLinkStep } from 'types'
import * as createLinkAsyncActions from 'data/store/reducers/create-link/async-actions'

const mapStateToProps = ({
  user: {
    address,
    provider,
    chainId,
    initialized: userInitialized
  },
  createLink: {
    step,
    tokenAmount,
    escrowAddress,
    isDepositWithAuthAvailable,
    humanReadableAmount,
    loading,
    humanReadableFeeAmount,
    feeTokenSymbol
  },
  token: {
    name,
    image,
    address: tokenAddress
  }
}: RootState) => ({
  address,
  step,
  humanReadableFeeAmount,
  provider,
  chainId,
  loading,
  isDepositWithAuthAvailable,
  userInitialized,
  feeTokenSymbol,
  tokenAddress,
  name,
  tokenAmount,
  escrowAddress,
  humanReadableAmount,
  image
})

const mapDispatcherToProps = (dispatch: Dispatch<CreateLinkActions> & IAppDispatch) => {
  return {
    setStep: (step: TCreateLinkStep) => dispatch(createLinkActions.setStep(step)),
    deposit: ( callback?: () => void) => dispatch(createLinkAsyncActions.deposit(callback)),
    depositWithAuth: ( callback?: () => void) => dispatch(createLinkAsyncActions.depositWithAuth(callback))
  }
}

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatcherToProps>

const DepositScreen: FC<ReduxType> = ({
  address,
  chainId,
  tokenAmount,
  deposit,
  setStep,
  loading,
  escrowAddress,
  humanReadableAmount,
  feeTokenSymbol,
  name,
  isDepositWithAuthAvailable,
  depositWithAuth,
  humanReadableFeeAmount,
  image
}) => {
  return <Container>
    <Subtitle>
      We'll create a shareable web link that your recipient can open to claim their crypto. If not claimed in 2 weeks, we'll return funds.
    </Subtitle>

    <TransactionContainer
      from={address}
      chainId={chainId as number}
      comissionToken={feeTokenSymbol}
      title='Amount to send (estimate)'
      to={escrowAddress || ''}
      comissionFee={humanReadableFeeAmount}
      symbol={name}
      amount={String(humanReadableAmount)}
      image={image as string}
    />

    <ButtonContainer>
      <ButtonStyled
        loading={loading}
        appearance='action'
        onClick={() => {
          if (isDepositWithAuthAvailable) {
            return depositWithAuth(() => {
              setStep('transaction_screen')
            })
          }
          deposit(() => {
            setStep('transaction_screen')
          })
          
        }}
      >
        Create
      </ButtonStyled>
    </ButtonContainer>
    
  </Container>
}

export default connect(mapStateToProps, mapDispatcherToProps)(DepositScreen)