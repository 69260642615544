import styled from "styled-components"

export const Container = styled.div`
  header {
    display: none;
  }
  @media (max-width: 767px) {
    header {
      display: block;
    }
  }
`