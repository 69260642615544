const defineRealNetworkName = (chainId: number | null) : string => {
  switch (chainId) {
    case 1: return 'Mainnet'
    case 5: return 'Goerli'
    case 137: return 'Polygon'
    case 11155111: return 'Sepolia'
    case 8453: return 'Base'
    case 84531: return 'Base Goerli'
    case 100: return 'Gnosis'
    case 43114: return 'Avalanche'
    case 42161: return 'Arbitrum'
    case 666666666: return 'Degen'
    case 10: return 'Optimism'
    default: return 'Mainnet'
  }
}

export default defineRealNetworkName
