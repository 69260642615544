import styled from "styled-components"

export const Grid = styled.div`
  display: grid;
  gap: 12px;
  font-size: 14px;
  line-height: 20px;
  grid-template-columns: 1fr 1fr;
`

export const GridLabel = styled.div`
  text-align: left;
  color:  ${props => props.theme.primaryTextColor};
`

export const GridDivider = styled.div`
  height: 1px;
  background-color: ${props => props.theme.primaryBorderColor};
  margin: 10px 0;
  grid-column: span 2;
`

export const GridValue = styled.div`
  text-align: right;
  color: ${props => props.theme.primaryTextColor};
`