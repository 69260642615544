
import { Dispatch } from 'redux'
import * as actionsCreateLink from '../actions'
import * as actionsUser from '../../user/actions'
import * as asyncActionsUser from '../../user/async-actions'
import { CreateLinkActions } from '../types'
import { TokenActions } from '../../token/types'
import { UserActions } from '../../user/types'
import getTokenBalance from '../../token/async-actions/get-token-balance'
import { RootState } from 'data/store'
import { alertError } from 'helpers'
import {
  TERC20Token,
  TTokenType
} from 'types'
import { maxLimitAmount } from 'configs/application'
import { datadogLogs } from '@datadog/browser-logs'

export default function updateInitialData(
  tokenAddress: string,
  limits: boolean
) {
  return async (
    dispatch: Dispatch<CreateLinkActions> & Dispatch<TokenActions> & Dispatch<UserActions>,
    getState: () => RootState
  ) => {
    dispatch(actionsCreateLink.setLoading(true))
    try {

      const {
        user: {
          provider,
          address,
          chainId,
          sdk
        },
        token: {
          tokenType
        }
      } = getState()

      if (!sdk) {
        return alertError('SDK is not provided')
      }

      if (
        tokenAddress
      ) {
        await getTokenBalance(
          dispatch,
          address,
          tokenAddress,
          provider
        )
        // await asyncActionsUser.getPrices(dispatch)
      }

      if (!limits) {
        dispatch(actionsCreateLink.setMaxTransferLimit(maxLimitAmount))
        dispatch(actionsCreateLink.setMinTransferLimit(0))
      } else {
        const limitsData = await sdk.getLimits({
          token: tokenAddress as string,
          tokenType: tokenType as TTokenType,
          chainId: Number(chainId)
        })
      
        if (limitsData) {
          dispatch(actionsCreateLink.setMaxTransferLimit(limitsData.maxTransferAmount))
          dispatch(actionsCreateLink.setMinTransferLimit(limitsData.minTransferAmount))
        }
      }
      dispatch(actionsCreateLink.setStep('set_amount')) 
    } catch (
      error: any
    ) {
      alertError(error.error)
      datadogLogs.logger.error('Error occurred', {}, new Error(error.error))
    }

    dispatch(actionsCreateLink.setLoading(false))
  }
}
