import { FC, useEffect } from 'react'
import { ScreenSubtitle, ScreenTitle, Container, ButtonComponent, IconContainer } from './styled-components'
import { RootState, IAppDispatch } from 'data/store'
import { connect } from 'react-redux'
import { defineExplorerURL } from 'helpers'
import * as createLinkAsyncActions from 'data/store/reducers/create-link/async-actions'
import { Loader } from 'components/common'
import { TCreateLinkStep } from 'types'
import * as createLinkActions from 'data/store/reducers/create-link/actions'
import { CreateLinkActions } from 'data/store/reducers/create-link/types'
import { Dispatch } from 'redux'

const mapStateToProps = ({
  createLink: { depositTxHash },
  user: { chainId }
}: RootState) => ({
  depositTxHash,
  chainId
})

const mapDispatcherToProps = (dispatch: Dispatch<CreateLinkActions> & IAppDispatch) => {
  return {
    checkDepositTransaction: (callback?: () => void) => dispatch(
      createLinkAsyncActions.checkDepositTransaction(callback)
    ),
    setStep: (step: TCreateLinkStep) => dispatch(createLinkActions.setStep(step)),
  }
}

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatcherToProps>

const TransactionScreen: FC<ReduxType> = ({
  depositTxHash,
  chainId,
  checkDepositTransaction,
  setStep
}) => {
  useEffect(() => {
    if (!depositTxHash) { return }
    checkDepositTransaction(() => {
      setStep('link_created')
    })
  }, [])

  const explorerUrl = chainId && depositTxHash ? <ButtonComponent
    href={`${defineExplorerURL(chainId)}/tx/${depositTxHash}`}
    title='View in explorer'
    target='_blank'
    appearance='default'
  /> : null
  return <Container>
    <IconContainer>
      <Loader />
    </IconContainer>
    <ScreenTitle>Processing Transaction</ScreenTitle>
    <ScreenSubtitle>This may take a few minutes. You can return to the app later to check on the status</ScreenSubtitle>
    {explorerUrl} 
  </Container>
}

export default connect(mapStateToProps, mapDispatcherToProps)(TransactionScreen)
