import { Dispatch } from 'redux';
import * as actions from '../actions';
import { UserActions } from '../types';
import { defineJSONRpcUrl, defineNetworkName, defineTokenAddressBySymbol } from 'helpers'
import { ethers } from 'ethers'
import { LinkdropP2P } from 'linkdrop-p2p-sdk'
import { CreateLinkActions } from '../../create-link/types'
import * as linkCreateActions from '../../create-link/actions'
import { providers } from 'ethers'
import randomBytes from 'randombytes'

const { REACT_APP_INFURA_ID, REACT_APP_ZUPLO_API_KEY } = process.env

const updateUserData = (
  address: string,
  urlTokenAddress?: string,
  connector?: any,
  callback?: () => void
) => {
  return async (
    dispatch: Dispatch<UserActions> & Dispatch<CreateLinkActions>
  ) => {
    dispatch(actions.setLoading(true))
    try {
      dispatch(actions.setHasConnector(true))
      dispatch(actions.setAddress(address))
      const provider = new providers.Web3Provider(await connector.getProvider())
      const network = await provider.getNetwork()
      if (provider) {
        const signer = await provider.getSigner()
        dispatch(actions.setChainId(network.chainId))
        dispatch(actions.setSigner(signer))
        dispatch(actions.setUserProvider(provider))
        if (connector.id) {
          dispatch(actions.setConnectorId(connector.id))
        }
        const jsonRpcUrl = defineJSONRpcUrl({
          chainId: Number(network.chainId),
          infuraPk: REACT_APP_INFURA_ID as string
        })
        const jsonRpcProvider = new ethers.providers.JsonRpcProvider(jsonRpcUrl)
        dispatch(actions.setProvider(jsonRpcProvider))
        const getRandomBytes = (length: number) => new Uint8Array(randomBytes(length)) 
        const sdk = new LinkdropP2P({
          apiKey: REACT_APP_ZUPLO_API_KEY as string,
          baseUrl: window.location.origin,
          getRandomBytes
        })
        dispatch(actions.setSDK(sdk))
        callback && callback()
      }
      
    } catch (err) {
      alert('Error occured with connector update')
    } 
    dispatch(actions.setLoading(false))
  }
}

export default updateUserData
