import { FC, useState } from 'react'
import { 
  InputStyled,
  Container,
  Title,
  Balance,
  BalanceValue,
  ButtonStyled,
  BorderedBlock,
  ErrorContainer,
  ChooseAssetButton,
  TokenImage,
  TokenSymbol
} from './styled-components'
import TProps from './types'
import { ethers } from 'ethers'
import { defineNetworkName, truncate } from 'helpers'
import Icons from 'icons'
import { useQuery } from 'hooks'

const defineError = (
  loading: boolean,
  minTransferLimit: ethers.BigNumberish,
  maxTransferLimit:  ethers.BigNumberish,
  balance:  ethers.BigNumberish,
  applicationLimitMaxFailed: boolean,
  applicationLimitMinFailed: boolean,
  userLimitFailed: boolean,
  symbol: string,
  networkName: string
) => {

  if (loading) {
    return `Loading...`
  }
  
  if (applicationLimitMaxFailed) {
    return `Max amount ${maxTransferLimit}`
  }
  if (applicationLimitMinFailed) {
    return `Amount should be more than or equal to ${minTransferLimit}`
  }
  if (userLimitFailed) {
    return `You do not have enough ${symbol} on ${networkName}. ${balance} available.`
  }
  return null
}

const ERC20Input: FC<TProps> = ({
  value,
  setValue,
  balance,
  balanceFailed,
  symbol,
  applicationLimitMaxFailed,
  applicationLimitMinFailed,
  userLimitFailed,
  loading,
  chainId,
  minTransferLimit,
  maxTransferLimit,
  toggleAsset,
  image,
  decimals,
  hideTokenList
}) => {
  const query = useQuery()
  const currentTheme = query.get('theme') === 'light' ? 'light' : 'dark'

  const [ isInitial, setInitial ] = useState<boolean>(true)
  const networkName = defineNetworkName(chainId)

  const amountError = !isInitial && defineError(
    loading,
    minTransferLimit,
    maxTransferLimit,
    balance,
    applicationLimitMaxFailed,
    applicationLimitMinFailed,
    userLimitFailed,
    symbol,
    networkName
  )

  return <Container>
    <BorderedBlock error={Boolean(amountError)}>
      <ChooseAssetButton
        currentTheme={currentTheme}
        hideTokenList={hideTokenList}
        onClick={() => {
        if (hideTokenList) { return }
        toggleAsset()
      }}>
        <TokenImage src={image} />
        <TokenSymbol>{symbol}</TokenSymbol>
        {!hideTokenList && <Icons.ArrowDownIcon />}
      </ChooseAssetButton>
      <Title>Token amount</Title>
      <InputStyled
        value={value}
        placeholder='0.0'
        onFocus={() => {
          if (value === '0.0') {
            setValue(``)
          }
        }}
        onChange={(value) => {
          setInitial(false)
          let valueDelimeters = value.match(/\.|\,/g);
          let hasTwoDelimeters = valueDelimeters !== null && valueDelimeters.length === 2
          if (value.length > 15) {
            return value
          }
          if (valueDelimeters !== null && valueDelimeters.length === 1 && decimals === 0) {
            return value
          }
          if (hasTwoDelimeters) {
            return value
          } else if (value === '.' || value === ','  || value === '') {
            setValue(``)
          } else if (/^[0-9.,]+$/.test(value)) {
            setValue(value.replace(',', '.'))
          } else if (!value) {
            setValue('')
          } else if (value === '') {
            setValue(`0`)
          }
          return value
        }}
      />
      <Balance>
        <BalanceValue>{truncate(balance, 6, '')}</BalanceValue> <TokenSymbol>{symbol}</TokenSymbol> available
      </Balance>
      <ButtonStyled
        appearance='additional'
        size='extra-small'
        onClick={() => {
          setValue(balance)
        }}
      >
        Max
      </ButtonStyled>
    </BorderedBlock>
    {amountError && <ErrorContainer>{amountError}</ErrorContainer>}
  </Container>
}

export default ERC20Input