import styled from "styled-components"
import { GradientWrapper } from 'components/pages/common'

export const TokenAmount = styled.span`
  font-size: 32px;
  text-align: center;
`

export const TitleERC20Component = styled.h4`
  font-size: 32px;
  margin: 0 0 4px;
  color:  ${props => props.theme.primaryTextColor};
  min-height: 110px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const SymbolERC20 = styled.span`
  margin-left: 4px;
  color: ${props => props.theme.primaryTextColor};
`

export const Container = styled.div`
  text-align: center;
  width: 100%;
  margin-bottom: 32px;
  padding-top: 40px;
`

export const GradientWrapperStyled = styled(GradientWrapper)`
  margin: 0 auto 12px;
  min-height: auto;
  width: 100%;
`

export const NetworkName = styled.div`
  font-size: 16px;
  font-weight: 400;
`

export const TokenAmountValue = styled.span`
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: 'ss01' on;
  letter-spacing: -0.02em;
`