import { FC } from 'react'
import Icons from 'icons'
import {
  HeaderLogo,
  Header,
  HeaderBack,
  NetworkSwitcherStyled
} from './styled-components'
import TProps from './types'
import { useTheme } from 'styled-components'

const PageHeader: FC<TProps> = ({
  backAction,
  title,
  chainId,
  switchNetwork,
  switchNetworkCallback
}) => {
  const theme = useTheme()

  return  <Header>
    {backAction && <HeaderBack onClick={backAction}>
      <Icons.ArrowLeftIcon />
    </HeaderBack>}
    {/* @ts-ignore */}
    {title || <HeaderLogo src={theme.logo as string} alt="Application Logo" />}
    <NetworkSwitcherStyled

      chainId={chainId}
      switchNetwork={switchNetwork}
      switchNetworkCallback={switchNetworkCallback}
    />
  </Header>
}

export default (PageHeader)