import { FC } from 'react'
import { Route, Switch, HashRouter } from 'react-router-dom'
import {
  NotFound,
  ClaimPage,
  HomePage,
  LinkStatusPage,
  CreateLinkPage
} from 'components/pages'

const AppRouter: FC = () => {
  return <HashRouter>
    <Switch>
      <Route path='/create/:chain_id/:token_address'>
        <CreateLinkPage />
      </Route>

      <Route path='/links/:chain_id/:transfer_id'>
        <LinkStatusPage />
      </Route>
      <Route path='/links/:chain_id/:transfer_id'>
        <LinkStatusPage />
      </Route>
      <Route path='/code'>
        <ClaimPage />
      </Route>

      <Route exact path='/'><HomePage /></Route>
      <Route path='*'><NotFound /></Route>
    </Switch>
  </HashRouter>
}

export default AppRouter