import { FC, useEffect } from 'react'
import {
  TitleComponent,
  ScreenButton,
  Subtitle,
  TokenImageContainer,
  TextComponent,
  UserAddress
} from './styled-components'
import { RootState, IAppDispatch } from 'data/store'
import { Dispatch } from 'redux'
import { LinkActions } from 'data/store/reducers/link/types'
import * as linkAsyncActions from 'data/store/reducers/link/async-actions'
import * as linkActions from 'data/store/reducers/link/actions'
import { TClaimLinkStep, TTokenType } from 'types'
import { shortenString, defineSystem } from 'helpers'
import { plausibleApi } from 'data/api'
import { Content } from '../styled-components'
import { ERC20TokenPreview, PoweredByFooter } from 'components/pages/common'
import { connect } from 'react-redux'
import { switchNetwork } from 'data/store/reducers/user/async-actions'

const mapStateToProps = ({
  token: { name, image, decimals, tokenId, amount, tokenType, address: tokenAddress },
  user: { address, chainId: userChainId, userProvider, email, signer },
  link: { isManual, loading, chainId }
}: RootState) => ({
  name,
  image,
  tokenType,
  tokenId,
  amount,
  isManual,
  loading,
  address,
  userChainId,
  chainId,
  tokenAddress,
  decimals,
  userProvider,
  email,
  signer
})

const mapDispatcherToProps = (dispatch: Dispatch<LinkActions> & IAppDispatch) => {
  return {
    claimERC1155: () => dispatch(
      linkAsyncActions.claimERC1155(
        undefined,
        true
      )
    ),
    claimERC721: () => dispatch(
      linkAsyncActions.claimERC721(
        undefined,
        true
      )
    ),
    claimERC20: () => dispatch(
      linkAsyncActions.claimERC20(
        undefined,
        true
      )
    ),
    setStep: (step: TClaimLinkStep) => dispatch(linkActions.setStep(step))
  }
}

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatcherToProps> 

const defineTokenId = (type: TTokenType | null, tokenId?: string | null) => {
  if (type === 'ERC20' || !tokenId) { return '' }
  if (tokenId.length > 5) {
    return ` #${shortenString(tokenId, 3)}`
  }
  return ` #${tokenId}`
}

const InitialScreen: FC<ReduxType> = ({
  name,
  tokenType,
  tokenId,
  amount,
  image,
  claimERC1155,
  claimERC721,
  claimERC20,
  loading,
  address,
  chainId,
  userChainId,
  setStep,
  tokenAddress,
  decimals,
  userProvider,
  email
}) => {

  const system = defineSystem()

  useEffect(() => {
    plausibleApi.invokeEvent({
      eventName: 'success_connect_wallet',
      data: {
        tokenAddress: String(tokenAddress),
        chainId: String(chainId)
      }
    })
  }, [])

  const defineButton = () => {
    return <ScreenButton
      disabled={
        (tokenType === 'ERC1155' && (!tokenId || !amount)) ||

        (tokenType === 'ERC721' && (!tokenId)) ||
        ((tokenType === 'ERC20' || tokenType === 'NATIVE') && (!amount)) ||
        loading
      }
      loading={loading}
      appearance='action'
      title='Claim'
      onClick={async () => {
        // if (Number(userChainId) !== Number(chainId) && userProvider) {
        //   // @ts-ignore
        //   if(window && window.ethereum && window.ethereum.isCoinbaseWallet && system !== 'desktop') {
        //     if (chainId) {
        //       await switchNetwork(userProvider, chainId, () => {})
        //     } else {
        //       alert('No chain provided')
        //     }
        //   } else {
        //     return setStep('change_network')
        //   }
        // }

        plausibleApi.invokeEvent({
          eventName: 'claim_initiated',
          data: {
            tokenAddress: String(tokenAddress),
            chainId: String(chainId)
          }
        })
        if (tokenType === 'ERC1155') {
          return claimERC1155()
        }
        if (tokenType === 'ERC721') {
          return claimERC721()
        }
        if (tokenType === 'ERC20' || tokenType === 'NATIVE') {
          return claimERC20()
        }
      }}
    />
  }

  const addressPreview = <UserAddress>{email ? email : shortenString(address, 3)}</UserAddress>

  const content = tokenType === 'ERC20' || tokenType === 'NATIVE' ? <>
    <ERC20TokenPreview
      name={name}
      image={image as string}
      amount={String(amount)}
      decimals={decimals}
      status='initial'
      chainId={chainId}
    />
    <TextComponent>
      Click claim to proceed claiming your tokens to the address: {addressPreview}
    </TextComponent>
  </> : <>
    {image && <TokenImageContainer src={image} alt={name} />}
    <Subtitle>{defineTokenId(tokenType, tokenId)}</Subtitle>
    <TitleComponent>{name}</TitleComponent>
    <TextComponent>
      Here is a preview of the NFT you’re about to receive to: {addressPreview}
    </TextComponent>
  </>

  return <Content> 
    {content}
    {defineButton()}
    <PoweredByFooter />
  </Content>
}

export default connect(mapStateToProps, mapDispatcherToProps)(InitialScreen)