import styled, { css } from "styled-components"
import { NetworkSwitcher } from '..'

export const Header = styled.header`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  font-size: 24px;
  line-height: 36px;
  min-height: 45px;
  color: ${props => props.theme.primaryTextColor};
`

export const HeaderLogo = styled.img`
  max-width: 120px;
`

export const HeaderBack = styled.div`
  cursor: pointer;
  display: flex;
  margin-right: 12px;

  svg {
    path {
      fill: ${props => props.theme.primaryTextColor};
    }
  }
`

export const NetworkSwitcherStyled = styled(NetworkSwitcher)`
  transition: border-color .3s, background-color .3s;
  &:hover {
    ${props => {
      return css`
        border-color: transparent;
        background-color: ${props => props.theme.tagDefaultColor};
      `
    }};
  }
  @media (min-width: 768px) {
    display: none;
  }
`
