import { TWalletName } from 'types'
import { сreateQueryString } from '.'

type TDefineRedeemLinkURL = (
  claimUrl: string,
  recommendedWalletApp: string | null,
  theme: string,
  redirectUrl?: string | null
) => string

const defineRedeemLinkURL: TDefineRedeemLinkURL = (
  claimUrl,
  recommendedWalletApp,
  theme,
  redirectUrl
) => {
  const queryParams = сreateQueryString({
    w: recommendedWalletApp || undefined,
    theme,
    r: redirectUrl ? encodeURIComponent(redirectUrl) : undefined
  })
  const result = `${claimUrl}&${queryParams}`

  return result
}

export default defineRedeemLinkURL