import { FC } from 'react'
import {
  PoweredBy,
  PoweredByImage
} from './styled-components'
import { plausibleApi } from 'data/api'
import { RootState } from 'data/store'
import { connect } from 'react-redux'
import { TProps } from './types'
import { useTheme } from 'styled-components'

const mapStateToProps = ({
  token: {
  }
}: RootState) => ({
})

type ReduxType = ReturnType<typeof mapStateToProps>

const PoweredByFooter: FC<ReduxType & TProps> = ({
  className
}) => {
  const theme = useTheme()

  return <PoweredBy className={className} onClick={() => {
    window.open('https://linkdrop.io', '_blank')
    plausibleApi.invokeEvent({
      eventName: 'powered_by_linkdrop_click'
    })
  }}>
    Powered by
    {/* @ts-ignore */}
    <PoweredByImage src={theme.logo as string} alt="Linkdrop Logo"/>
  </PoweredBy>
}


export default connect(mapStateToProps)(PoweredByFooter)