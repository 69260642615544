import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import { Button } from 'linkdrop-ui'

export const ButtonLink = styled(Link)`
  text-decoration: none;
  width: 100%;
`

export const Anchor = styled.a`
  text-decoration: none;
  width: 100%;

  button {
    width: 100%;
  }
`


export const ButtonStyled = styled(Button)`
  ${props => props.size === 'large' && css`
    min-height: 56px;
  `}

  ${props =>  {
    if (props.appearance === 'default' || !props.appearance) {
      return css`
        border-color: ${props.theme.buttonDefaultBorderColor};
      `
    } else {
      return css`
        border-color: ${props.theme.buttonActionBorderColor};
      `
    }
  }}



  ${props =>  {
    if (props.appearance === 'action') {
      return css`
        color: ${props.theme.buttonActionTextColor};
      `
    } else if (props.appearance === 'default') {
      return css`
        color: ${props.theme.buttonDefaultTextColor};
      `
    }
  }}
`