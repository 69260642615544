const { REACT_APP_INFURA_ID } = process.env

type TChains = {
  [chainId: number]: {
    chainName: string
    displayName: string
    testnet: boolean
    alchemySupport: boolean
    nativeCurrency: {
      name: string
      symbol: string
      decimals: number
    },
    rpcUrls: string[]
    blockExplorerUrls: string[]
  }
}

const chains: TChains = {
  137: {
    chainName: 'Polygon',
    displayName: 'Polygon',
    testnet: false,
    alchemySupport: true,
    nativeCurrency: {
      name: 'MATIC',
      symbol: 'MATIC',
      decimals: 18
    },
    rpcUrls: [
      'https://poly-rpc.gateway.pokt.network/'
    ],
    blockExplorerUrls: [
      'https://polygonscan.com'
    ]
  },
  8453: {
    chainName: 'Base',
    displayName: 'Base',
    testnet: false,
    alchemySupport: false,
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18
    },
    rpcUrls: [
      'https://developer-access-mainnet.base.org'
    ],
    blockExplorerUrls: [
      'https://basescan.org'
    ]
  },
  11155111: {
    chainName: 'Sepolia',
    displayName: 'Sepolia',
    testnet: true,
    alchemySupport: true,
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18
    },
    rpcUrls: [
      'https://ethereum-sepolia-rpc.publicnode.com'
    ],
    blockExplorerUrls: [
      'https://sepolia.etherscan.io'
    ]
  },
  // 84531: {
  //   chainName: 'Base Goerli Testnet',
  //   displayName: 'BaseGoerli',
  //   testnet: true,
  //   alchemySupport: false,
  //   nativeCurrency: {
  //     name: 'ETH',
  //     symbol: 'ETH',
  //     decimals: 18
  //   },
  //   rpcUrls: [
  //     'https://base-goerli.public.blastapi.io'
  //   ],
  //   blockExplorerUrls: [
  //     'https://goerli.basescan.org'
  //   ]
  // },
  100: {
    chainName: 'Gnosis',
    displayName: 'Gnosis',
    testnet: false,
    alchemySupport: false,
    nativeCurrency: {
      name: 'XDAI',
      symbol: 'XDAI',
      decimals: 18
    },
    rpcUrls: [
      'https://rpc.gnosischain.com'
    ],
    blockExplorerUrls: [
      'https://gnosisscan.io'
    ]
  },
  43114: {
    chainName: 'Avalanche',
    displayName: 'Avalanche',
    testnet: false,
    alchemySupport: true,
    nativeCurrency: {
      name: 'AVAX',
      symbol: 'AVAX',
      decimals: 18
    },
    rpcUrls: [
      'https://api.avax.network/ext/bc/C/rpc'
    ],
    blockExplorerUrls: [
      'https://snowtrace.io'
    ]
  },
  42161: {
    chainName: 'Arbitrum',
    displayName: 'Arbitrum',
    testnet: false,
    alchemySupport: true,
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18
    },
    rpcUrls: [
      'https://arbitrum.llamarpc.com'
    ],
    blockExplorerUrls: [
      'https://arbiscan.io'
    ]
  },
  10: {
    chainName: 'Optimism',
    displayName: 'Optimism',
    testnet: false,
    alchemySupport: true,
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18
    },
    rpcUrls: [
      'https://optimism.llamarpc.com'
    ],
    blockExplorerUrls: [
      'https://optimistic.etherscan.io'
    ]
  },
  666666666: {
    chainName: 'Degen Chain',
    displayName: 'Degen',
    testnet: false,
    alchemySupport: false,
    nativeCurrency: {
      name: 'DEGEN',
      symbol: 'DEGEN',
      decimals: 18
    },
    rpcUrls: [
      'https://rpc.degen.tips'
    ],
    blockExplorerUrls: [
      'https://explorer.degen.tips'
    ]
  }
}

export default chains