import LinkdropLogo from 'images/linkdrop.png'
import LinkdropLightLogo from 'images/linkdrop-light.png'

const themes = (
  theme
) => {

  const themesConfigs = {
    dark: {
      primaryTextColor: '#FFF', // main color of text
      secondaryTextColor: '#1D1F2B', // secondary color of text
      additionalTextColor: '#9D9D9D', // secondary color of text
      extraTextColor: '#6A6A6A', // secondary color of text
      disabledTextColor: '#DFE0EB', // disabled color of text
      dangerTextColor: '#FF2C2C', // danger color of text
      noteTextColor: '#D4CCFF', // note color of text
      primaryBorderColor: '#E4E4E4',
      logo: LinkdropLightLogo,
      secondaryBorderColor: '#000',
      additionalBorderColor: '#272727',
      primaryBackgroundColor: '#171920', // main color of bg
      secondaryColor: '#363740', // secondary color of bg
      secondaryBackgroundColor: '#1D1F2B', // secondary color of bg
      menuItemActive: '#3E4049',
      primaryHighlightColor: '#0057FF',
      loaderPrimaryColor: '#000',
      widgetColor: '#171920',
    
      loaderColor: '#0057FF',
      linkColor: '#0057FF',


      // buttons
      buttonActionBackgroundColor: '#0057FF',
      buttonActionTextColor: '#FFF',
      buttonActionBackgroundHoverColor: '#357AFF',
      buttonActionBackgroundActiveColor: '#095AF5',

      buttonDisabledBackgroundColor: '#E4E4E4',

      buttonDefaultBackgroundColor: 'transparent',
      buttonDefaultBackgroundHoverColor: 'transparent',
      buttonDefaultBackgroundActiveColor: 'transparent',
      buttonDefaultBorderColor: '#3D3F49',
      buttonDefaultBorderHoverColor: '#FFF',
      buttonDefaultBorderActiveColor: '#FFF',
      buttonDefaultTextColor: '#FFF',
      buttonDefaultDisabledBorderColor: '#E4E4E4',
      buttonDefaultDisabledTextColor: '#9D9D9D',

      buttonAdditionalBackgroundColor: '#E4EDFF',
      buttonAdditionalBackgroundHoverColor: '#E4EDFF',
      buttonAdditionalTextHoverColor: '#357AFF',
      buttonAdditionalBackgroundActiveColor: '#E4EDFF',
      buttonAdditionalTextActiveColor: '#095AF5',

      buttonGradient: 'linear-gradient(to right, #00B2FF 0%, #FA00FF 50%, #00B2FF 100%)',
      
      errorColor: '#FF5252',

      tagDefaultColor: '#29406D',
      tagDefaultTextColor: '#0057FF',
      tagErrorColor: '#FF2C2C',
      tagInfoColor: '#2B32EA',
      tagSuccessColor: '#21F142',

      noteDefaultBgColor: '#E4EDFF',
      noteDefaultTextColor: '#0057FF',
      noteAttentionBgColor: '#272727',
      noteAttentionTextColor: '#C3C3C3',

      inputHoverBorderColor: '#121212',
      inputDisabledBackgroundColor: '#E4E4E4',

      ethereumLogoColor: '#0EBDCD',
      polygonLogoColor: '#0EBDCD',
      toastBackgroundColor: '#2D2A3D',
    },

    light: {
      primaryTextColor: '#121212', // main color of text
      secondaryTextColor: '#FFF', // secondary color of text
      additionalTextColor: '#9D9D9D', // secondary color of text
      extraTextColor: '#6A6A6A', // secondary color of text
      disabledTextColor: '#DFE0EB', // disabled color of text
      dangerTextColor: '#FF2C2C', // danger color of text
      noteTextColor: '#D4CCFF', // note color of text
      primaryBorderColor: '#E4E4E4',
      secondaryBorderColor: '#000',
      additionalBorderColor: '#272727',
      primaryBackgroundColor: '#FFF', // main color of bg
      secondaryColor: '#363740', // secondary color of bg
      secondaryBackgroundColor: '#F7F8FA', // secondary color of bg
      menuItemActive: '#3E4049',
      primaryHighlightColor: '#0C5EFF',
      loaderPrimaryColor: '#000',
      widgetColor: '#FFF',
    
      loaderColor: '#0C5EFF',
      logo: LinkdropLogo,
      linkColor: '#0C5EFF',


      // buttons
      buttonActionBackgroundColor: '#0C5EFF',
      buttonActionTextColor: '#FFF',
      buttonActionBackgroundHoverColor: '#357AFF',
      buttonActionBackgroundActiveColor: '#095AF5',

      buttonDisabledBackgroundColor: '#E4E4E4',

      buttonDefaultBackgroundColor: 'transparent',
      buttonDefaultBackgroundHoverColor: 'transparent',
      buttonDefaultBackgroundActiveColor: 'transparent',
      buttonDefaultBorderHoverColor: '#121212',
      buttonDefaultBorderColor: '#E4E4E4',
      buttonDefaultBorderActiveColor: '#121212',
      buttonDefaultTextColor: '#000',
      buttonDefaultDisabledBorderColor: '#E4E4E4',
      buttonDefaultDisabledTextColor: '#9D9D9D',

      buttonAdditionalBackgroundColor: '#E4EDFF',
      buttonAdditionalBackgroundHoverColor: '#E4EDFF',
      buttonAdditionalTextHoverColor: '#357AFF',
      buttonAdditionalBackgroundActiveColor: '#E4EDFF',
      buttonAdditionalTextActiveColor: '#095AF5',

      buttonGradient: 'linear-gradient(to right, #00B2FF 0%, #FA00FF 50%, #00B2FF 100%)',
      
      errorColor: '#FF5252',

      tagDefaultColor: '#E4EDFF',
      tagDefaultTextColor: '#0C5EFF',
      tagErrorColor: '#FF2C2C',
      tagInfoColor: '#2B32EA',
      tagSuccessColor: '#21F142',

      noteDefaultBgColor: '#E4EDFF',
      noteDefaultTextColor: '#0C5EFF',
      noteAttentionBgColor: '#272727',
      noteAttentionTextColor: '#C3C3C3',

      inputHoverBorderColor: '#121212',
      inputDisabledBackgroundColor: '#E4E4E4',

      ethereumLogoColor: '#0EBDCD',
      polygonLogoColor: '#0EBDCD',
      toastBackgroundColor: '#2D2A3D',
    }
  }


  if (!theme || (theme !== 'light' && theme !== 'dark')) {
    return themesConfigs.light
  }

  return themesConfigs[theme]
}

export default themes