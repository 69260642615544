export enum Constants {
  CREATE_LINK_SET_STEP = 'CREATE_LINK_SET_STEP',
  CREATE_LINK_SET_LOADING = 'CREATE_LINK_SET_LOADING',
  CREATE_LINK_SET_INITIALIZED = 'CREATE_LINK_SET_INITIALIZED',
  CREATE_LINK_SET_AMOUNT = 'CREATE_LINK_SET_AMOUNT',
  CREATE_LINK_SET_HUMAN_READABLE_AMOUNT = 'CREATE_LINK_SET_HUMAN_READABLE_AMOUNT',
  CREATE_LINK_SET_TRANSFER_ID = 'CREATE_LINK_SET_TRANSFER_ID',
  CREATE_LINK_SET_MAX_TRANSFER_LIMIT = 'CREATE_LINK_SET_MAX_TRANSFER_LIMIT',
  CREATE_LINK_SET_MIN_TRANSFER_LIMIT = 'CREATE_LINK_SET_MIN_TRANSFER_LIMIT',
  CREATE_LINK_SET_RECOMMENDED_WALLET = 'CREATE_LINK_SET_RECOMMENDED_WALLET',

  CREATE_LINK_SET_DEPOSIT_TX_HASH = 'CREATE_LINK_SET_DEPOSIT_TX_HASH',
  CREATE_LINK_SET_APPROVE_TX_HASH = 'CREATE_LINK_SET_APPROVE_TX_HASH',


  CREATE_LINK_SET_FEE_TOKEN = 'CREATE_LINK_SET_FEE_TOKEN',
  CREATE_LINK_SET_FEE_TOKEN_SYMBOL = 'CREATE_LINK_SET_FEE_TOKEN_SYMBOL',
  CREATE_LINK_SET_FEE_AMOUNT = 'CREATE_LINK_SET_FEE_AMOUNT',
  CREATE_LINK_SET_HUMAN_READABLE_FEE_AMOUNT = 'CREATE_LINK_SET_HUMAN_READABLE_FEE_AMOUNT',

  CREATE_LINK_SET_TOKEN_DATA_INITIALIZED = 'CREATE_LINK_SET_TOKEN_DATA_INITIALIZED',

  CREATE_LINK_SET_ESCROW_ADDRESS = 'CREATE_LINK_SET_ESCROW_ADDRESS',
  CREATE_LINK_SET_IS_DEPOSIT_WITH_AUTH_AVAILABLE = 'CREATE_LINK_SET_IS_DEPOSIT_WITH_AUTH_AVAILABLE'

}